import { lazy } from "react"

const Dashboard = lazy(() => import("./views/Dashboard/DashboardContainer"))
const Search = lazy(() => import("./views/Search/SearchContainer"))
const Profile = lazy(() => import("./views/Profile/ProfileContainer"))
const JobDescription = lazy(() =>
  import("./views/JobDescription/JobDescriptionContainer"),
)
const Apply = lazy(() => import("./views/Apply/ApplyContainer"))
const Applications = lazy(() =>
  import("./views/Applications/ApplicationsContainer"),
)
const SavedJobs = lazy(() => import("./views/SavedJobs/SavedJobsContainer"))

const routes = [
  { path: "/dashboard", exact: true, name: "Home", component: Dashboard },
  {
    path: "/search",
    exact: true,
    name: "Search",
    component: Search,
    secured: false,
  },
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    component: Profile,
    secured: true,
  },
  {
    path: "/applications",
    exact: true,
    name: "Applications",
    component: Applications,
    secured: true,
  },
  {
    path: "/job/:id",
    exact: true,
    name: "Job Description",
    component: JobDescription,
  },
  {
    path: "/job/:id/apply",
    exact: true,
    name: "Apply",
    component: Apply,
    secured: true,
  },
  {
    path: "/saved-jobs",
    exact: true,
    name: "Saved Jobs",
    component: SavedJobs,
  },
]

export default routes
