import {
  applicantCompleteStatus,
  applicantIncompleteStatus,
} from "../constants/constants"
import { removeApplicantStatus, setApplicantStatus } from "../utils/utils"
import { resetFullState } from "../actions/generalActions"

export const applicantMiddleware = ({ dispatch }) => next => action => {
  switch (action.type) {
    case "DOES_APPLICANT_EXIST_SUCCESS":
      setApplicantStatus(
        action.doesExist ? applicantCompleteStatus : applicantIncompleteStatus,
      )
      next(action)
      break

    case "DOES_APPLICANT_EXIST_ERROR":
      removeApplicantStatus()
      next(action)
      break

    case "LOGOUT_REQUEST":
      removeApplicantStatus()
      next(action)
      dispatch(resetFullState())
      break

    default:
      next(action)
      break
  }
}
