import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { createStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Snackbar from "@material-ui/core/Snackbar"
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import "url-search-params-polyfill"
import { loginRequest } from "haystack-auth"
import { connect } from "react-redux"
import { getUserServiceApiURL, authConfig } from "../../constants/constants"

const styles = theme =>
  createStyles({
    formGrid: {
      height: "100vh",
    },
    formContainer: {
      padding: theme.spacing(2),
    },
    cardContainer: {
      maxWidth: 650,
    },
    textButton: {
      textTransform: "none",
      padding: 0,
    },
    changePasswordButton: {
      marginTop: theme.spacing(2),
    },
  })

class ResetContainer extends Component {
  constructor() {
    super()
    this.state = { passwordText: "", confirmText: "", formErrors: {} }
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn: prevIsLoggedIn } = prevProps
    const { isLoggedIn, history } = this.props

    // If the user was on the reset password page and logged in, the login page would redirect
    // the user to the reset password page. If this happens, just send the user back to /search
    if (isLoggedIn === !prevIsLoggedIn) {
      history.push("/search")
    }
  }

  isFormValid = () => {
    const { passwordText, confirmText } = this.state
    if (passwordText !== confirmText || passwordText === "") {
      return false
    }

    if (passwordText.length < 6 || passwordText.length > 128) {
      return false
    }

    return true
  }

  handlePasswordDidChange = e => {
    this.setState({ passwordText: e.target.value })
  }

  handleConfirmDidChange = e => {
    this.setState({ confirmText: e.target.value })
  }

  handleFocusDidChange = () => {
    const { passwordText, confirmText } = this.state

    const newFormErrors = {}

    if (passwordText !== confirmText && confirmText !== "") {
      newFormErrors.password = "Passwords do not match"
    }

    this.setState({ formErrors: newFormErrors })
  }

  handleDidClickResetPasswordButton = () => {
    const { passwordText, token } = this.state

    fetch(`${getUserServiceApiURL()}/password-reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: passwordText,
        reset_token: token,
      }),
    })
      .then()
      .then(async res => {
        if (res.status === 400) {
          const body = await res.json()
          this.setState({ formErrors: body })
        } else if (res.status === 200) {
          this.setState({
            showSuccessSnackbar: true,
            passwordText: "",
            confirmText: "",
          })
        }
      })
  }

  render() {
    const { classes, login } = this.props
    const {
      token,
      passwordText,
      formErrors,
      confirmText,
      showSuccessSnackbar,
    } = this.state

    const params = new URLSearchParams(window.location.search)
    const paramsToken = params.get("token")
    if (token !== paramsToken) {
      this.setState({ token: paramsToken })
    }

    return (
      <Box display="flex" justifyContent="center">
        <Grid
          container
          direction="column"
          justify="center"
          className={classes.formGrid}
        >
          <Grid item>
            <Box justifyContent="center" display="flex">
              <div className={classes.cardContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h3" align="center">
                      Reset Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      fullWidth
                      type="password"
                      value={passwordText}
                      onChange={this.handlePasswordDidChange}
                      error={formErrors.password}
                      helperText={formErrors.password}
                      onBlur={this.handleFocusDidChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Confirm"
                      fullWidth
                      type="password"
                      value={confirmText}
                      onChange={this.handleConfirmDidChange}
                      onBlur={this.handleFocusDidChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      className={classes.changePasswordButton}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        label="Register"
                        primary
                        onClick={this.handleDidClickResetPasswordButton}
                        disabled={!this.isFormValid(this.state)}
                      >
                        Reset Password
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
        </Grid>
        <Snackbar
          open={showSuccessSnackbar}
          message="Password successfully reset"
          action={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Button color="secondary" size="small" onClick={() => login()}>
              Login
            </Button>
          }
        />
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
})

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(loginRequest(authConfig)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(ResetContainer)))
