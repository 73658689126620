import React from "react"
import { Route } from "react-router-dom"
import HaystackRedirect from "./HaystackRedirect"

export default function SecureHaystackRoute({
  component,
  authenticated,
  ...rest
}) {
  return authenticated ? (
    <Route {...rest} component={component} />
  ) : (
    <Route
      {...rest}
      render={props => <HaystackRedirect {...props} {...rest} />}
    />
  )
}
