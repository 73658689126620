import _ from "lodash"
import {
  FETCH_BASIC_DATA_BEGAN,
  FETCH_BASIC_DATA_FAILURE,
  FETCH_BASIC_DATA_SUCCESS,
  FETCH_BASIC_DATA_DEPENDENCIES_SUCCESS,
  FETCH_BASIC_DATA_DEPENDENCIES_BEGAN,
  FETCH_BASIC_DATA_DEPENDENCIES_FAILURE,
  RESET_FULL_STATE,
} from "../actions/basicDataActions"

export const initialState = {
  basicData: {},
  dependencies: {},
  err: undefined,
  dependenciesErr: undefined,
  isLoading: false,
  dependenciesIsLoading: false,
}

function handleFetchBasicDataBeganAction(state) {
  return {
    ...state,
    isLoading: true,
  }
}

function handleFetchBasicDataDependenciesBeganAction(state) {
  return {
    ...state,
    dependenciesIsLoading: true,
  }
}

function handleFetchBasicDataSuccessAction(state, action) {
  const clonedBasicData = _.cloneDeep(action.payload)

  // Flattening out data shape. Get the shape in the way the application-service wants it
  // on its way into the store
  _.keys(clonedBasicData).forEach(key => {
    clonedBasicData[key] = clonedBasicData[key].map(element => ({
      id: element.other.serial_key,
      name: element.title,
      code: element.other.code,
    }))
  })

  const basicData = {
    ...state.basicData,
    ...clonedBasicData,
  }

  return {
    ...state,
    isLoading: false,
    err: undefined,
    basicData,
  }
}

function handleFetchBasicDataDependenciesSuccessAction(state, action) {
  let { dependencies } = state

  dependencies = {
    ...dependencies,
    [action.dependency]: action.basicData,
  }

  return {
    ...state,
    dependenciesIsLoading: false,
    dependenciesErr: undefined,
    dependencies,
  }
}

function handleFetchBasicDataFailureAction(state, action) {
  return {
    ...state,
    isLoading: false,
    err: action.payload,
  }
}

function handleFetchBasicDataDependenciesFailureAction(state, action) {
  return {
    ...state,
    dependenciesIsLoading: false,
    dependenciesErr: action.payload,
  }
}

export function basicDataReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BASIC_DATA_BEGAN:
      return handleFetchBasicDataBeganAction(state, action)
    case FETCH_BASIC_DATA_DEPENDENCIES_BEGAN:
      return handleFetchBasicDataDependenciesBeganAction(state, action)
    case FETCH_BASIC_DATA_SUCCESS:
      return handleFetchBasicDataSuccessAction(state, action)
    case FETCH_BASIC_DATA_DEPENDENCIES_SUCCESS:
      return handleFetchBasicDataDependenciesSuccessAction(state, action)
    case FETCH_BASIC_DATA_FAILURE:
      return handleFetchBasicDataFailureAction(state, action)
    case FETCH_BASIC_DATA_DEPENDENCIES_FAILURE:
      return handleFetchBasicDataDependenciesFailureAction(state, action)
    case RESET_FULL_STATE:
      // Don't reset basic data state since data is the same
      return state
    default:
      return state
  }
}
