import { IJobRequisition } from "./JobRequisition"

interface IEducationLevel {
  id: number
  active: boolean
  description: string
  name: string
}

interface IEducation {
  id: number
  level: IEducationLevel
  degree: string
  institution: string
  notes: string
}

interface IReference {
  id: number
  title: string
  name: string
  organization: string
  homePhone: string
  workPhone: string
  relationship: string
}

interface IDocument {
  id: number
  type: string
  file: {
    id: number
    fileName: string
    mimeType: string
    s3Key: string
  }
}

interface ILanguageProficiency {
  canRead: boolean
  canWrite: boolean
  canSpeak: boolean
  language: ILanguage
}

interface ILanguage {
  id: number
  name: string
  description: string
}

interface IBranchOfService {
  id: number
  name: string
  description: string
}

interface IRank {
  id: number
  name: string
  description: string
}

interface IMilitaryExperience {
  id: number
  monthsOfService: number
  branchOfService: IBranchOfService
  rank: IRank
}

interface IClearanceType {
  id: number
  name: string
  description: string
}

interface IClearanceGrantor {
  id: number
  name: string
  description: string
}

interface IClearance {
  id: number
  type: IClearanceType
  grantor: IClearanceGrantor
  comments: string
  expirationDate: string
}

export interface IApplicant {
  firstName: string
  middleName: string
  lastName: string
  homePhone: string
  mobilePhone: string
  emailAddress: string
  address: IAddress
  disabilityStatus: IDisabilityStatus
  veteranStatus: IVeteranStatus
  ethnicityType: IEthnicityType
  gender: string
  citizenship: ICitizenship
  dob: string
  emergencyContacts: IContacts[]
  workHistory: IWorkHistory[]
  skillsets: ISkillset[]
  educationRecords: IEducation[]
  references: IReference[]
  documents: IDocument[]
  languages: ILanguageProficiency[]
  militaryExperienceRecords: IMilitaryExperience[]
  clearances: IClearance[]
  savedJobs: { [key: number]: IJobRequisition }
}

export interface ICountry {
  id: number
  name: string
  code: string
}

export interface IState {
  name: string
  code: string
}

interface IAddress {
  id: number
  active: boolean
  addressLine1: string
  addressLine2: string
  city: string
  state: IState
  country: ICountry
  zip: string
}

interface IContacts {
  lastName: string
  firstName: string
  number: string
  relationship: string
}

interface IDisabilityStatus {
  id?: number
  name: string
  description: string
}

interface IVeteranStatus {
  id: number
  name: string
  description: string
}

interface IEthnicityType {
  id: number
  name: string
  description: string
}

interface ICitizenship {
  birthCountry: ICountry
}

interface IWorkHistory {
  id?: number
  employerName: string
  description: string
  endDate: string
  startDate: string
  jobTitle: string
  location: string
}

interface ISkillset {
  id: number
  notes: string
  skillLevel: ISkillLevel
  jobSkill: IJobSkill
  skillSpecialization: ISkillSpecialization
  years: string
}

interface ISkillSpecialization {
  name: string
  description: string
  jobSkill: IJobSkill
}

interface IJobSkill {
  name
  description
}

interface ISkillLevel {
  name: string
  description: string
}

function getDefaultAddress(): IAddress {
  return {
    id: undefined,
    active: true,
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: undefined,
    state: undefined,
    zip: "",
  }
}

function getDefaultDisabilityStatus(): IDisabilityStatus {
  return { description: "", name: "" }
}

export function getDefaultApplicant(): IApplicant {
  return {
    clearances: [],
    address: getDefaultAddress(),
    citizenship: undefined,
    disabilityStatus: getDefaultDisabilityStatus(),
    dob: undefined,
    documents: [],
    educationRecords: [],
    emailAddress: "",
    emergencyContacts: [],
    ethnicityType: undefined,
    firstName: "",
    gender: "",
    homePhone: "",
    languages: [],
    lastName: "",
    middleName: "",
    militaryExperienceRecords: [],
    mobilePhone: "",
    references: [],
    skillsets: [],
    veteranStatus: undefined,
    workHistory: [],
    savedJobs: {},
  }
}
