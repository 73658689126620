import axios from "axios"
import { getBasicDataServiceApiURL } from "../constants/constants"

export const FETCH_BASIC_DATA_BEGAN = "FETCH_BASIC_DATA_BEGAN"
export const FETCH_BASIC_DATA_DEPENDENCIES_BEGAN =
  "FETCH_BASIC_DATA_DEPENDENCIES_BEGAN"
export const FETCH_BASIC_DATA_SUCCESS = "FETCH_BASIC_DATA_SUCCESS"
export const FETCH_BASIC_DATA_DEPENDENCIES_SUCCESS =
  "FETCH_BASIC_DATA_DEPENDENCIES_SUCCESS"
export const FETCH_BASIC_DATA_FAILURE = "FETCH_BASIC_DATA_FAILURE"
export const FETCH_BASIC_DATA_DEPENDENCIES_FAILURE =
  "FETCH_BASIC_DATA_DEPENDENCIES_FAILURE"
export const RESET_FULL_STATE = "RESET_FULL_STATE"

export const fetchBasicDataBegan = () => ({
  type: FETCH_BASIC_DATA_BEGAN,
})

export const fetchBasicDataDependenciesBegan = () => ({
  type: FETCH_BASIC_DATA_DEPENDENCIES_BEGAN,
})

export const fetchBasicDataSuccess = (basicData, query) => ({
  type: FETCH_BASIC_DATA_SUCCESS,
  payload: basicData,
  query,
})

export const fetchBasicDataDependenciesSuccess = (basicData, dependency) => ({
  type: FETCH_BASIC_DATA_DEPENDENCIES_SUCCESS,
  basicData,
  dependency,
})

export const fetchBasicDataFailure = err => ({
  type: FETCH_BASIC_DATA_FAILURE,
  payload: err,
})

export const fetchBasicDataDependenciesFailure = err => ({
  type: FETCH_BASIC_DATA_DEPENDENCIES_FAILURE,
  payload: err,
})

export const fetchBasicData = (query, token, showLoading = true) => {
  return dispatch => {
    if (showLoading) dispatch(fetchBasicDataBegan())

    axios
      .post(`${getBasicDataServiceApiURL()}/basic-data/search`, query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => dispatch(fetchBasicDataSuccess(res.data, query)))
      .catch(err => dispatch(fetchBasicDataFailure(err)))
  }
}

export const fetchBasicDataDependencies = (
  query,
  token,
  showLoading = true,
) => {
  return dispatch => {
    if (showLoading) dispatch(fetchBasicDataDependenciesBegan())

    axios
      .post(`${getBasicDataServiceApiURL()}/basic-data/dependencies`, query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res =>
        dispatch(
          fetchBasicDataDependenciesSuccess(res.data, query.dependency_field),
        ),
      )
      .catch(err => dispatch(fetchBasicDataDependenciesFailure(err)))
  }
}
