import axios from "axios"
import { getPortalApiURL } from "../constants/constants"

export const FETCH_JOB_REQUISITION_BEGAN = "FETCH_JOB_REQUISITION_BEGAN"
export const FETCH_JOB_REQUISITION_SUCCESS = "FETCH_JOB_REQUISITION_SUCCESS"
export const FETCH_JOB_REQUISITION_FAILURE = "FETCH_JOB_REQUISITION_FAILURE"
export const RESET_FULL_STATE = "RESET_FULL_STATE"

export const fetchJobRequisitionBegan = () => ({
  type: FETCH_JOB_REQUISITION_BEGAN,
})

export const fetchJobRequisitionSuccess = jobRequisition => ({
  type: FETCH_JOB_REQUISITION_SUCCESS,
  jobRequisition,
})

export const fetchJobRequisitionFailure = error => ({
  type: FETCH_JOB_REQUISITION_FAILURE,
  error,
})

export const fetchJobRequisition = id => {
  return async dispatch => {
    dispatch(fetchJobRequisitionBegan())
    axios
      .get(`${getPortalApiURL()}/job-requisitions/${id}`)
      .then(res => dispatch(fetchJobRequisitionSuccess(res.data)))
      .catch(error => dispatch(fetchJobRequisitionFailure(error)))
  }
}
