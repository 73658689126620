import { schema } from "normalizr"

export const NormalizedStates = new schema.Entity("states")
export const NormalizedCountries = new schema.Entity("countries")
export const NormalizedDisabilityStatus = new schema.Entity(
  "disabilityStatuses",
)
export const NormalizedVeteranStatus = new schema.Entity("veteranStatuses")
export const NormalizedEthnicityTypes = new schema.Entity("ethnicityTypes")
export const NormalizedSkillLevels = new schema.Entity("skillLevels")
export const NormalizedSkillSpecializations = new schema.Entity(
  "skillSpecializations",
)
export const NormalizedJobSkills = new schema.Entity("jobSkills")
export const NormalizedLanguages = new schema.Entity("languages")
export const NormalizedSavedJobs = new schema.Entity("savedJobs")
export const NormalizedJobApplications = new schema.Entity("jobApplications")
