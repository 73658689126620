import { createStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import InputBase from "@material-ui/core/InputBase"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import GpsFixedIcon from "@material-ui/icons/GpsFixed"
import MenuIcon from "@material-ui/icons/Menu"
import SearchIcon from "@material-ui/icons/Search"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Autocomplete } from "@material-ui/lab"
import _ from "lodash"
import {
  setQueries,
  fetchSearchQuerySuggestions,
  fetchGeographicSearchQuerySuggestions,
  setSearchQuery,
  setGeographicQuery,
  setCategoryFilterFromURL,
} from "../../actions/searchActions"
import { toggleSidebar } from "../../actions/sidebarActions"
import "url-search-params-polyfill"

const styles = ({ spacing, shape, breakpoints, transitions }) =>
  createStyles({
    title: {},
    search: {
      position: "relative",
      height: "100%",
      borderRadius: shape.borderRadius,
      // backgroundColor: fade(palette.common.white, 0.15),
      // "&:hover": {
      //   backgroundColor: fade(palette.common.white, 0.25),
      // },
      backgroundColor: "white",
      color: "black",
      [breakpoints.down("sm")]: {
        flexGrow: 1,
      },
      [breakpoints.up("sm")]: {
        marginLeft: spacing(2),
      },
    },
    searchButton: {
      fontFamily: "OverpassBlack",
      height: "100%",
      marginLeft: spacing(2),
      backgroundColor: "#AE9142",
      //  MozBorderRadius: "20px",
      WebkitBorderRadius: "20px",
    },
    searchIcon: {
      width: spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      //  color: "inherit",
      color: "black",
    },
    inputInput: {
      padding: spacing(1, 1, 1, 7),
      transition: transitions.create("width"),
      [breakpoints.up("md")]: {
        width: 180,
        "&:focus": {
          width: 280,
        },
      },
      [breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    menuButton: {
      height: "100%",
    },
    grow: {
      flexGrow: 1,
    },
  })

class SearchRow extends Component {
  constructor(props) {
    super(props)

    const {
      handleFetchSearchQuerySuggestions,
      handleFetchGeographicSearchQuerySuggestions,
    } = this.props

    this.debouncedSearch = _.debounce(handleFetchSearchQuerySuggestions, 100)
    this.debouncedGeographicSearch = _.debounce(
      handleFetchGeographicSearchQuerySuggestions,
      100,
    )
  }

  componentDidMount() {
    const {
      handleSetQueries,
      searchQuery,
      geographicQuery,
      handleSetSearchQuery,
      location,
      handleSetCategoryFilterFromURL,
    } = this.props

    const params = new URLSearchParams(location.search)
    const term = params.get("term")
    const category = _.get(location, "params.id", null)

    if (category) {
      handleSetCategoryFilterFromURL(parseInt(category, 10))
    }

    if (term) {
      handleSetSearchQuery(term)
      handleSetQueries(term, geographicQuery)
    } else {
      handleSetQueries(searchQuery, geographicQuery)
    }
  }

  onKeyPress = e => {
    const { handleSetQueries, searchQuery, geographicQuery } = this.props

    if (e.keyCode === 13) {
      handleSetQueries(searchQuery, geographicQuery)
    }
  }

  render() {
    const {
      classes,
      handleToggleSidebar,
      searchQuerySuggestions,
      geographicSearchQuerySuggestions,
      searchQuery,
      handleSetSearchQuery,
      handleSetGeographicQuery,
      geographicQuery,
      handleSetQueries,
    } = this.props

    return (
      <Box
        width="100%"
        flexWrap="nowrap"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Hidden smUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={handleToggleSidebar}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <Typography className={classes.title} variant="subtitle1" noWrap>
            Search Jobs
          </Typography>
        </Hidden>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <Autocomplete
            freeSolo
            options={searchQuerySuggestions.documents.map(
              document => document.suggestion,
            )}
            value={searchQuery}
            onChange={(event, value) => {
              handleSetSearchQuery(value)
            }}
            renderInput={params => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={{
                  "aria-label": "search",
                  ...params.inputProps,
                }}
                fullWidth
                placeholder="Title, skills, keyword"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={e => {
                  handleSetSearchQuery(e.target.value)
                  this.debouncedSearch(e.target.value)
                }}
                onKeyDown={this.onKeyPress}
                value={searchQuery}
              />
            )}
          />
        </div>
        <Hidden smDown>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <GpsFixedIcon />
            </div>
            <Autocomplete
              freeSolo
              options={geographicSearchQuerySuggestions.documents.map(
                document => document.suggestion,
              )}
              value={geographicQuery}
              onChange={(event, value) => {
                handleSetGeographicQuery(value)
              }}
              renderInput={params => (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={{
                    "aria-label": "geographic search",
                    ...params.inputProps,
                  }}
                  fullWidth
                  placeholder="Country, State, or Zip"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  onChange={e => {
                    handleSetGeographicQuery(e.target.value)
                    this.debouncedGeographicSearch(e.target.value)
                  }}
                  onKeyDown={this.onKeyPress}
                  value={geographicQuery}
                />
              )}
            />
          </div>
        </Hidden>
        <Button
          color="primary"
          variant="contained"
          className={classes.searchButton}
          onClick={() => {
            handleSetQueries(searchQuery, geographicQuery)
          }}
        >
          <Hidden xsDown>Search</Hidden>
          <Hidden smUp>
            <SearchIcon />
          </Hidden>
        </Button>
      </Box>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  handleSetQueries: (titleQuery, geographicQuery) =>
    dispatch(setQueries(titleQuery, geographicQuery)),
  handleSetSearchQuery: searchQuery => dispatch(setSearchQuery(searchQuery)),
  handleSetGeographicQuery: geographicQuery =>
    dispatch(setGeographicQuery(geographicQuery)),
  handleToggleSidebar: () => dispatch(toggleSidebar()),
  handleFetchSearchQuerySuggestions: query =>
    dispatch(fetchSearchQuerySuggestions(query)),
  handleFetchGeographicSearchQuerySuggestions: query =>
    dispatch(fetchGeographicSearchQuerySuggestions(query)),
  handleSetCategoryFilterFromURL: category =>
    dispatch(setCategoryFilterFromURL(category)),
})

const mapStateToProps = state => ({
  searchResponse: state.searchReducer.searchResponse,
  searchQuery: state.searchReducer.searchQuery,
  geographicQuery: state.searchReducer.geographicQuery,
  searchQuerySuggestions: state.searchReducer.searchQuerySuggestions,
  geographicSearchQuerySuggestions:
    state.searchReducer.geographicSearchQuerySuggestions,
  queryString: state.searchReducer.queryString,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SearchRow))
