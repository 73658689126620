import red from "@material-ui/core/colors/red"
import { getCurrentEnvironment } from "../utils/utils"
import {
  ISearchRequestFacets,
  ISearchRequestFilters,
} from "../models/requests/SearchRequest"

export enum Env {
  LOCAL,
  DEV,
  STAGING,
  PROD,
}

export function getPortalApiURL(): string {
  switch (getCurrentEnvironment()) {
    case Env.LOCAL:
      return "https://api-dev.missionessential.io/application"
    // return "http://localhost:8090"
    case Env.DEV:
      return "https://api-dev.missionessential.io/application"
    case Env.STAGING:
      return "https://api-stage.missionessential.io/application"
    case Env.PROD:
      return "https://api-prod.missionessential.io/application"
    default:
      return "https://api-dev.missionessential.io/application"
  }
}

export function getAuthServiceApiURL(): string {
  switch (getCurrentEnvironment()) {
    case Env.LOCAL:
      return "https://auth-dev.missionessential.io"
    case Env.DEV:
      return "https://auth-dev.missionessential.io"
    case Env.STAGING:
      return "https://auth-stage.missionessential.io"
    case Env.PROD:
      return "https://auth.missionessential.io"
    default:
      return "https://auth-dev.missionessential.io"
  }
}

export function getUserServiceApiURL(): string {
  switch (getCurrentEnvironment()) {
    case Env.LOCAL:
      return "https://api-dev.missionessential.io/user"
    case Env.DEV:
      return "https://api-dev.missionessential.io/user"
    case Env.STAGING:
      return "https://api-stage.missionessential.io/user"
    case Env.PROD:
      return "https://api-prod.missionessential.io/user"
    default:
      return "https://api-dev.missionessential.io/user"
  }
}

export function getBasicDataServiceApiURL(): string {
  switch (getCurrentEnvironment()) {
    case Env.LOCAL:
      return "https://api-dev.missionessential.io/basic-data"
    case Env.DEV:
      return "https://api-dev.missionessential.io/basic-data"
    case Env.STAGING:
      return "https://api-stage.missionessential.io/basic-data"
    case Env.PROD:
      return "https://api-prod.missionessential.io/basic-data"
    default:
      return "https://api-dev.missionessential.io/basic-data"
  }
}

export function getCurrentEnvURL(): string {
  switch (getCurrentEnvironment()) {
    case Env.LOCAL:
      return "http://localhost:3000"
    case Env.DEV:
      return "https://portal-dev.missionessential.io"
    case Env.STAGING:
      return "https://portal-stage.missionessential.io"
    case Env.PROD:
      return "https://careers.missionessential.com"
    default:
      return "http://localhost:3000"
  }
}

export function getClientID(): string {
  switch (getCurrentEnvironment()) {
    case Env.LOCAL:
      return "sampleClientId"
    // return "portal_stage"
    case Env.DEV:
      return "sampleClientId"
    case Env.STAGING:
      return "portal_stage"
    case Env.PROD:
      return "portal"
    default:
      return "sampleClientId"
  }
}

export function getApplicationTitle(): string {
  switch (getCurrentEnvironment()) {
    case Env.PROD:
      return "Mission Essential Careers"
    case Env.STAGING:
      return "STAGE - Mission Essential Careers"
    default:
      return "DEV - Mission Essential Careers"
  }
}

export function shouldEnableReduxLogging(): boolean {
  switch (getCurrentEnvironment()) {
    case Env.PROD:
      return false
    case Env.STAGING:
      return false
    // return true
    default:
      return true
  }
}

export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_PAGE = 1

export const authConfig = {
  url: `${getAuthServiceApiURL()}/oauth/authorize`,
  client: getClientID(),
  redirect: `${getCurrentEnvURL()}/search`,
  scope: "applicant:read applicant:write",
  width: 780,
  height: 550,
}

export const unauthenticatedRedirectPath = "/search"

export const portalFacets = (center): ISearchRequestFilters => {
  const facets: ISearchRequestFacets = {
    job_req_area_name: {
      type: "value",
    },
    country_name: {
      type: "value",
    },
    state_name: {
      type: "value",
    },
  }

  if (center) {
    facets.job_location_coords = {
      type: "range",
      center,
      unit: "mi",
      ranges: [
        { from: 0, to: 20, name: "0-20" },
        { from: 20, to: 40, name: "20-40" },
        { from: 40, to: 60, name: "40-60" },
        { from: 60, to: 80, name: "60-80" },
        { from: 80, to: 100, name: "80-100" },
        { from: 100, to: 120, name: "100-120" },
        { from: 120, to: 140, name: "120-140" },
        { from: 140, to: 160, name: "140-160" },
        { from: 160, to: 180, name: "160-180" },
        { from: 180, to: 200, name: "180-200" },
        { from: 200, name: "200" },
      ],
    }
  }

  return facets
}

export const phoneNumberRegx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const tokenKey = "APPLICANT_STATUS"
export const applicantIncompleteStatus = "INCOMPLETE"
export const applicantCompleteStatus = "COMPLETE"

export const dangerColor = red[500]
