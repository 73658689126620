import jp from "jsonpath"
import cloneDeep from "lodash/cloneDeep"
import moment from "moment"
import axios, { CancelTokenSource } from "axios"
import {
  IAddAdditionalCollectionElement,
  IDoesApplicantExistSuccess,
  IFetchApplicantError,
  IFetchApplicantSuccess,
  IFetchDocumentsBegan,
  IFetchDocumentsFailure,
  IFetchDocumentsSuccess,
  IFetchSavedJobsBegan,
  IFetchSavedJobsFailure,
  IFetchSavedJobsSuccess,
  IMergeEducationRecordsForm,
  IMergeExperienceForm,
  IMergeLanguagesForm,
  IMergeMilitaryClearancesForm,
  IMergePersonalForm,
  IMergeReferencesForm,
  IMergeSkillsForm,
  IPushSavedJob,
  IPopDeletedSavedJob,
  IRemoveCollectionElement,
  ISaveJobBegan,
  IDeleteSavedJobBegan,
  ISaveJobFailure,
  IDeleteSavedJobFailure,
  ISaveJobSuccess,
  IDeleteSavedJobSuccess,
  ICreateOrUpdateApplicantBegan,
  ICreateOrUpdateApplicantFailure,
  ICreateOrUpdateApplicantSuccess,
  ISetFormsToast,
  applicantActions,
} from "../actions/applicantActions"
import { IApplicant, getDefaultApplicant } from "../models/Applicant"

const cancelToken = axios.CancelToken
const source = cancelToken.source()

interface IApplicantState {
  applicant: IApplicant
  error?: Error
  isApplicantUpdating: boolean
  isLoading: boolean
  doesExist: boolean
  isSaveJobLoading: boolean
  isDeleteSavedJobLoading: boolean
  isFetchingDocuments: boolean
  saveJobError?: Error
  deleteSavedJobError?: Error
  isFetchingDocumentsError?: Error
  isFetchSavedJobLoading: boolean
  fetchSavedJobError?: Error
  formsToastOpen: boolean
  cancelToken: CancelTokenSource
}

const initialState: IApplicantState = {
  applicant: getDefaultApplicant(),
  isLoading: true,
  isApplicantUpdating: false,
  error: undefined,
  doesExist: false,
  isSaveJobLoading: false,
  isDeleteSavedJobLoading: false,
  isFetchingDocuments: false,
  isFetchingDocumentsError: undefined,
  isFetchSavedJobLoading: false,
  fetchSavedJobError: undefined,
  saveJobError: undefined,
  deleteSavedJobError: undefined,
  formsToastOpen: false,
  cancelToken: source,
}

function handleAddAdditionalCollectionElement(
  state: IApplicantState,
  action: IAddAdditionalCollectionElement,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  const val = jp.value(newApplicant, action.path)
  if (val.length <= 0) val.push({})
  val.push({})
  jp.value(newApplicant, action.path, val)
  return {
    ...state,
    applicant: newApplicant,
  }
}

function handleRemoveCollectionElementAction(
  state: IApplicantState,
  action: IRemoveCollectionElement,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  const val = jp.value(newApplicant, action.path)
  val.splice(action.index, action.index)
  jp.value(newApplicant, action.path, val)
  return {
    ...state,
    applicant: newApplicant,
  }
}

function keyToId(key): number {
  switch (key) {
    case "HOME":
      return 1
    case "MOBILE":
      return 2
    case "EMAIL":
    default:
      return 4
  }
}

function setContactInfo(records, key, value): void {
  if (!value) {
    return
  }

  const idx = records.findIndex(record => record.type.name === key)
  if (idx !== -1) {
    // eslint-disable-next-line no-param-reassign
    records[idx].contactInfo = value
  } else {
    records.push({
      active: true,
      contactInfo: value,
      type: {
        id: keyToId(key),
        active: true,
        name: key,
        description: key,
      },
    })
  }
}

function handleMergePersonalFormAction(
  action: IMergePersonalForm,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  newApplicant.firstName = action.formData.firstName
  newApplicant.middleName = action.formData.middleName
  newApplicant.lastName = action.formData.lastName

  if (!newApplicant.contactInfoRecords) newApplicant.contactInfoRecords = []

  setContactInfo(
    newApplicant.contactInfoRecords,
    "HOME",
    action.formData.homePhone,
  )
  setContactInfo(
    newApplicant.contactInfoRecords,
    "MOBILE",
    action.formData.mobilePhone,
  )
  setContactInfo(
    newApplicant.contactInfoRecords,
    "EMAIL",
    action.formData.email,
  )

  if (!newApplicant.address) newApplicant.address = {}
  newApplicant.address.addressLine1 = action.formData.addressLine1
  newApplicant.address.addressLine2 = action.formData.addressLine2
  newApplicant.address.city = action.formData.city
  newApplicant.address.country = action.formData.country
  newApplicant.address.state = action.formData.state
  newApplicant.address.zip = action.formData.zip
  /*
  Hardcoding type for address to be primary from database. Once models are migrated out of
  scope-db into postgres, this can be removed, and should be unneeded
  */
  newApplicant.address.type = { id: 1 }

  newApplicant.disabilityStatus = action.formData.disabilityStatus
  newApplicant.veteranStatus = action.formData.veteranStatus
  newApplicant.ethnicityType = action.formData.ethnicity
  newApplicant.gender = action.formData.gender

  if (!newApplicant.citizenship) newApplicant.citizenship = {}
  newApplicant.citizenship.birthCountry = action.formData.birthCountry

  newApplicant.dob = moment(action.formData.dateOfBirth).toISOString()

  if (!newApplicant.emergencyContacts[0]) newApplicant.emergencyContacts[0] = {}
  newApplicant.emergencyContacts[0].firstName =
    action.formData.emergencyContactFirstName
  newApplicant.emergencyContacts[0].lastName =
    action.formData.emergencyContactLastName
  newApplicant.emergencyContacts[0].number =
    action.formData.emergencyContactPhoneNumber
  newApplicant.emergencyContacts[0].relationship =
    action.formData.emergencyContactRelationship

  return {
    ...state,
    applicant: newApplicant,
  }
}

function handlecreateOrUpdateApplicantBeganAction(
  action: ICreateOrUpdateApplicantBegan,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isApplicantUpdating: true,
  }
}

function handlecreateOrUpdateApplicantSuccessAction(
  action: ICreateOrUpdateApplicantSuccess,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    applicant: {
      ...state.applicant,
      ...action.applicant,
    },
    isApplicantUpdating: false,
  }
}

function handlecreateOrUpdateApplicantFailureAction(
  action: ICreateOrUpdateApplicantFailure,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isApplicantUpdating: false,
  }
}

function handleMergeExperienceFormAction(
  action: IMergeExperienceForm,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  const workHistory = action.formData.records.map(history => ({
    id: history.id,
    employerName: history.employer,
    jobTitle: history.title,
    location: history.location,
    description: history.description,
    startDate: moment(history.startDate).toISOString(),
    endDate: moment(history.endDate).toISOString(),
  }))

  newApplicant.workHistory = workHistory

  return {
    ...state,
    applicant: newApplicant,
  }
}

function handleMergeSkillsFormAction(
  action: IMergeSkillsForm,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)

  newApplicant.skillsets = action.formData.skillsets

  return {
    ...state,
    applicant: newApplicant,
  }
}

function handleMergeEducationRecordsFormAction(
  action: IMergeEducationRecordsForm,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  newApplicant.educationRecords = action.formData.educationRecords

  return {
    ...state,
    applicant: newApplicant,
  }
}

function handleMergeReferencesFormAction(
  action: IMergeReferencesForm,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  newApplicant.references = action.formData.references

  return {
    ...state,
    applicant: newApplicant,
  }
}

function handleMergeLanguagesFormAction(
  action: IMergeLanguagesForm,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  newApplicant.languages = action.formData.languages

  return {
    ...state,
    applicant: newApplicant,
  }
}

function handleMergeMilitaryClearancesForm(
  action: IMergeMilitaryClearancesForm,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  newApplicant.militaryExperienceRecords =
    action.formData.militaryExperienceRecords
  newApplicant.clearances = action.formData.clearances.map(clearance => ({
    ...clearance,
    expirationDate: moment(clearance.expirationDate).toISOString(),
  }))

  return {
    ...state,
    applicant: newApplicant,
  }
}

function handleFetchDocumentsBeganAction(
  action: IFetchDocumentsBegan,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isFetchingDocuments: true,
  }
}

function handleFetchDocumentsSuccessAction(
  action: IFetchDocumentsSuccess,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  newApplicant.documents = action.documents
  return {
    ...state,
    isFetchingDocuments: false,
    isFetchingDocumentsError: undefined,
    applicant: newApplicant,
  }
}

function handleFetchDocumentsFailureAction(
  action: IFetchDocumentsFailure,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isFetchingDocuments: false,
    isFetchingDocumentsError: action.error,
  }
}

function handleFetchApplicantBeganAction(
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isLoading: true,
    error: undefined,
  }
}

function handleFetchApplicantSuccessAction(
  state: IApplicantState,
  action: IFetchApplicantSuccess,
): IApplicantState {
  return {
    ...state,
    isLoading: false,
    error: undefined,
    // When updating the applicant, only overwrite fields that are new from `action.applicant`,
    // but keep any existing fields that aren't included in `action.applicant`
    applicant: {
      ...state.applicant,
      ...(action.applicant ? action.applicant : getDefaultApplicant()),
    },
  }
}

function handleFetchApplicantErrorAction(
  state: IApplicantState,
  action: IFetchApplicantError,
): IApplicantState {
  return {
    ...state,
    isLoading: false,
    error: action.error,
    applicant: getDefaultApplicant(),
  }
}

function handleSetApplicantJsonPathValueAction(
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  return {
    ...state,
    applicant: newApplicant,
  }
}

function handleDoesApplicantExistFailureAction(
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    doesExist: false,
  }
}

function handleDoesApplicantExistSuccessAction(
  state: IApplicantState,
  action: IDoesApplicantExistSuccess,
): IApplicantState {
  return {
    ...state,
    doesExist: action.doesExist,
  }
}

function handleDoesApplicantExistBeginAction(
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    doesExist: false,
  }
}

function handleSaveJobBeganAction(
  action: ISaveJobBegan,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isSaveJobLoading: true,
  }
}

function handleDeletedSavedJobBeganAction(
  action: IDeleteSavedJobBegan,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isDeleteSavedJobLoading: true,
  }
}

function handleSaveJobSuccessAction(
  action: ISaveJobSuccess,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isSaveJobLoading: false,
    saveJobError: undefined,
  }
}

function handleDeleteSavedJobSuccessAction(
  action: IDeleteSavedJobSuccess,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isDeleteSavedJobLoading: false,
    deleteSavedJobError: undefined,
  }
}

function handleSaveJobFailureAction(
  action: ISaveJobFailure,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isSaveJobLoading: false,
    saveJobError: action.error,
  }
}

function handleDeleteSavedJobFailureAction(
  action: IDeleteSavedJobFailure,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isDeleteSavedJobLoading: false,
    deleteSavedJobError: action.error,
  }
}

function handleFetchSavedJobsBeganAction(
  action: IFetchSavedJobsBegan,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isFetchSavedJobLoading: true,
  }
}

function handleFetchSavedJobsSuccessAction(
  action: IFetchSavedJobsSuccess,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)
  newApplicant.savedJobs = action.savedJobs
  return {
    ...state,
    applicant: newApplicant,
    isFetchSavedJobLoading: false,
    fetchSavedJobError: undefined,
  }
}

function handleFetchSavedJobsFailureAction(
  action: IFetchSavedJobsFailure,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    isFetchSavedJobLoading: false,
    fetchSavedJobError: action.error,
  }
}

function handlePushSavedJobAction(
  action: IPushSavedJob,
  state: IApplicantState,
): IApplicantState {
  const newApplicant = cloneDeep(state.applicant)

  if (!newApplicant.savedJobs) newApplicant.savedJobs = {}

  newApplicant.savedJobs[action.jobReqId] = {}

  return {
    ...state,
    applicant: newApplicant,
  }
}

function handlePopDeletedSavedJobAction(
  action: IPopDeletedSavedJob,
  state: IApplicantState,
): IApplicantState {
  const applicant = cloneDeep(state.applicant)

  if (!applicant.savedJobs) {
    applicant.savedJobs = {}
  } else {
    delete applicant.savedJobs[action.jobReqId]
  }

  return {
    ...state,
    applicant,
  }
}

function handleSetFormsToast(
  action: ISetFormsToast,
  state: IApplicantState,
): IApplicantState {
  return {
    ...state,
    formsToastOpen: action.open,
  }
}

function handleLoginSuccess(state: IApplicantState): IApplicantState {
  return {
    ...state,
    cancelToken: cancelToken.source(),
  }
}

function handleLogoutRequest(state: IApplicantState): IApplicantState {
  state.cancelToken.cancel("logout")

  return state
}

export function applicantReducer(
  state = initialState,
  action: applicantActions,
): IApplicantState {
  switch (action.type) {
    case "FETCH_DOCUMENTS_BEGAN":
      return handleFetchDocumentsBeganAction(action, state)
    case "FETCH_DOCUMENTS_SUCCESS":
      return handleFetchDocumentsSuccessAction(action, state)
    case "FETCH_DOCUMENTS_FAILURE":
      return handleFetchDocumentsFailureAction(action, state)
    case "MERGE_MILITARY_CLEARANCES_FORM":
      return handleMergeMilitaryClearancesForm(action, state)
    case "MERGE_LANGUAGES_FORM":
      return handleMergeLanguagesFormAction(action, state)
    case "MERGE_REFERENCES_FORM":
      return handleMergeReferencesFormAction(action, state)
    case "MERGE_EDUCATION_RECORDS_FORM":
      return handleMergeEducationRecordsFormAction(action, state)
    case "MERGE_SKILLS_FORM":
      return handleMergeSkillsFormAction(action, state)
    case "UPDATE_APPLICANT_BEGAN":
      return handlecreateOrUpdateApplicantBeganAction(action, state)
    case "UPDATE_APPLICANT_SUCCESS":
      return handlecreateOrUpdateApplicantSuccessAction(action, state)
    case "UPDATE_APPLICANT_FAILURE":
      return handlecreateOrUpdateApplicantFailureAction(action, state)
    case "MERGE_PERSONAL_FORM":
      return handleMergePersonalFormAction(action, state)
    case "MERGE_EXPERIENCE_FORM":
      return handleMergeExperienceFormAction(action, state)
    case "PUSH_SAVED_JOB":
      return handlePushSavedJobAction(action, state)
    case "POP_DELETED_SAVED_JOB":
      return handlePopDeletedSavedJobAction(action, state)
    case "FETCH_SAVED_JOBS_BEGAN":
      return handleFetchSavedJobsBeganAction(action, state)
    case "FETCH_SAVED_JOBS_SUCCESS":
      return handleFetchSavedJobsSuccessAction(action, state)
    case "FETCH_SAVED_JOBS_FAILURE":
      return handleFetchSavedJobsFailureAction(action, state)
    case "SAVE_JOB_BEGAN":
      return handleSaveJobBeganAction(action, state)
    case "DELETE_SAVED_JOB_BEGAN":
      return handleDeletedSavedJobBeganAction(action, state)
    case "SAVE_JOB_SUCCESS":
      return handleSaveJobSuccessAction(action, state)
    case "DELETE_SAVED_JOB_SUCCESS":
      return handleDeleteSavedJobSuccessAction(action, state)
    case "SAVE_JOB_FAILURE":
      return handleSaveJobFailureAction(action, state)
    case "DELETE_SAVED_JOB_FAILURE":
      return handleDeleteSavedJobFailureAction(action, state)
    case "DOES_APPLICANT_EXIST_BEGAN":
      return handleDoesApplicantExistBeginAction(state)
    case "DOES_APPLICANT_EXIST_SUCCESS":
      return handleDoesApplicantExistSuccessAction(state, action)
    case "DOES_APPLICANT_EXIST_ERROR":
      return handleDoesApplicantExistFailureAction(state)
    case "FETCH_APPLICANT_BEGAN":
      return handleFetchApplicantBeganAction(state)
    case "FETCH_APPLICANT_SUCCESS":
      return handleFetchApplicantSuccessAction(state, action)
    case "FETCH_APPLICANT_ERROR":
      return handleFetchApplicantErrorAction(state, action)
    case "SET_APPLICANT_JSON_PATH_VALUE":
      return handleSetApplicantJsonPathValueAction(state)
    case "ADD_ADDITIONAL_COLLECTION_ELEMENT":
      return handleAddAdditionalCollectionElement(state, action)
    case "REMOVE_COLLECTION_ELEMENT":
      return handleRemoveCollectionElementAction(state, action)
    case "SET_FORMS_TOAST":
      return handleSetFormsToast(action, state)
    case "LOGIN_SUCCESS":
      return handleLoginSuccess(state)
    case "LOGOUT_REQUEST":
      return handleLogoutRequest(state)
    case "RESET_FULL_STATE":
      return initialState

    default:
      return state
  }
}
