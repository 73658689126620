import { withStyles, Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { ConnectedRouter } from "connected-react-router"
import { loginRequest, logoutRequest } from "haystack-auth"
import React, { Component, Suspense } from "react"
import { connect } from "react-redux"
import { Redirect, Route, Switch } from "react-router-dom"
import SecureHaystackRoute from "../../components/SecureHaystackRoute"
import { authConfig } from "../../constants/constants"
import routes from "../../routes"
import AppContainer from "./AppContainer"
import PortalAppBar from "./PortalAppBar"
import { fetchSavedJobs } from "../../actions/applicantActions"
import { handleResize } from "../../actions/generalActions"
import {
  setJobNotFoundAlert,
  clearFullSearch,
} from "../../actions/searchActions"

function Secure() {
  return <h2>Secure</h2>
}

function searchBarSpacer() {
  return <div style={{ height: 128, width: "100%" }} />
}

function Unsecure() {
  return <h2>{process.env.REACT_APP_ENV}</h2>
}

class AppLayout extends Component {
  componentDidMount() {
    const {
      getSavedJobs,
      token,
      cancelToken,
      doResize,
      isAuthenticated,
    } = this.props

    if (isAuthenticated) {
      getSavedJobs(token, cancelToken)
    }

    window.addEventListener("resize", doResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.doResize)
  }

  loading = () => <div />

  render() {
    const {
      isAuthenticated,
      login,
      logout,
      history,
      jobNotFoundAlert,
      handleSetJobNotFoundAlert,
      handleClearFullSearch,
    } = this.props

    const renderRoute = route => {
      if (route.component) {
        if (route.secured) {
          return (
            <SecureHaystackRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
              name={route.name}
              authenticated={isAuthenticated}
              login={login}
              render={props => <route.component {...props} />}
            />
          )
        }

        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={props => <route.component {...props} />}
          />
        )
      }

      return null
    }

    return (
      <ConnectedRouter history={history}>
        <Snackbar
          open={jobNotFoundAlert.open}
          autoHideDuration={5000}
          onClose={() => handleSetJobNotFoundAlert(false, null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {`Job ID "${jobNotFoundAlert.id}" not found`}
          </Alert>
        </Snackbar>
        <SecureHaystackRoute
          authenticated={isAuthenticated}
          component={Secure}
          path="/secure"
          login={login}
        />
        <PortalAppBar
          isAuthenticated={isAuthenticated}
          logout={logout}
          login={login}
          clearFullSearch={handleClearFullSearch}
        />
        {searchBarSpacer()}
        <AppContainer>
          <Suspense fallback={this.loading()}>
            <Switch>
              <Redirect exact from="/" to="/search" />
              {routes.map(route => renderRoute(route))}
              <Redirect exact from="/jobs/job/:id" to="/job/:id" />
              <Route
                path="/jobs/search"
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: "/search",
                    }}
                  />
                )}
              />
              <Route
                path="/jobs/jobCategory/:id"
                component={({ location, match }) => (
                  <Redirect
                    to={{
                      ...location,
                      ...match,
                      pathname: "/search",
                    }}
                  />
                )}
              />
              <Redirect to="/404" />
              <Redirect />
            </Switch>
          </Suspense>
        </AppContainer>
        <Route path="/unsecure" component={Unsecure} />
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.loginReducer.isLoggedIn,
  token: state.loginReducer.token,
  cancelToken: state.general.cancelToken,
  jobNotFoundAlert: state.searchReducer.jobNotFoundAlert,
})

const mapDispatchToProps = dispatch => ({
  handleClearFullSearch: () => dispatch(clearFullSearch()),
  login: () => dispatch(loginRequest(authConfig)),
  logout: () => dispatch(logoutRequest()),
  getSavedJobs: (token, cancelToken) =>
    dispatch(fetchSavedJobs(token, cancelToken)),
  doResize: () => dispatch(handleResize(window.innerWidth)),
  handleSetJobNotFoundAlert: (open, id) =>
    dispatch(setJobNotFoundAlert(open, id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles({}, { withTheme: true })(AppLayout))
