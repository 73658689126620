import axios, { CancelTokenSource } from "axios"
import { normalize } from "normalizr"
import { Dispatch } from "redux"
import { getPortalApiURL } from "../constants/constants"
import { NormalizedJobApplications } from "../models/NormalizerSchema"
import { IPortalJobApplication } from "../models/PortalJobApplication"
import { portalJobApplicationToCreateApplicationRequest } from "../models/requests/CreateApplicationRequest"
import { IResetFullState } from "./generalActions"

interface IAnswer {
  questionGroupId: number
  questionId: number
  questionWrapperId: number
  answerId: number
  answer?: string
  id?: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IJobApplication {}

export interface IAddOrUpdateQuestionAnswer {
  type: "ADD_OR_UPDATE_QUESTION_ANSWER"
  answer: IAnswer
}

export interface ICreateApplicationBegan {
  type: "CREATE_APPLICATION_BEGAN"
}

export interface ICreateApplicationSuccess {
  type: "CREATE_APPLICATION_SUCCESS"
}

export interface ICreateApplicationFailure {
  type: "CREATE_APPLICATION_FAILURE"
  error: Error
}

export interface IFetchJobApplicationsBegan {
  type: "FETCH_JOB_APPLICATIONS_BEGAN"
}

export interface IFetchJobApplicationsSuccess {
  type: "FETCH_JOB_APPLICATIONS_SUCCESS"
  jobApplications: { [id: number]: IJobApplication[] }
}

export interface IFetchJobApplicationsFailure {
  type: "FETCH_JOB_APPLICATIONS_FAILURE"
  error: Error
}

export interface IInvalidJobApplicationsCache {
  type: "INVALIDATE_JOB_APPLICATIONS_CACHE"
}

export interface ISetAlreadyApplied {
  type: "SET_ALREADY_APPLIED"
  applied: boolean
}

export type jobApplicationActions =
  | IAddOrUpdateQuestionAnswer
  | ICreateApplicationBegan
  | ICreateApplicationSuccess
  | ICreateApplicationFailure
  | IFetchJobApplicationsBegan
  | IFetchJobApplicationsSuccess
  | IFetchJobApplicationsFailure
  | ISetAlreadyApplied
  | IInvalidJobApplicationsCache
  | IResetFullState

export const addOrUpdateQuestionAnswer = (
  answer: IAnswer,
): IAddOrUpdateQuestionAnswer => ({
  type: "ADD_OR_UPDATE_QUESTION_ANSWER",
  answer,
})

export const createApplicationBegan = (): ICreateApplicationBegan => ({
  type: "CREATE_APPLICATION_BEGAN",
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICreateApplicationRequest {}

export const createApplicationSuccess = (): ICreateApplicationSuccess => ({
  type: "CREATE_APPLICATION_SUCCESS",
})

export const createApplicationFailure = (
  error: Error,
): ICreateApplicationFailure => ({
  type: "CREATE_APPLICATION_FAILURE",
  error,
})

export const fetchJobApplicationsBegan = (): IFetchJobApplicationsBegan => ({
  type: "FETCH_JOB_APPLICATIONS_BEGAN",
})

export const fetchJobApplicationsSuccess = (applications: {
  [id: number]: IJobApplication[]
}): IFetchJobApplicationsSuccess => ({
  type: "FETCH_JOB_APPLICATIONS_SUCCESS",
  jobApplications: applications,
})

export const fetchJobApplicationsFailure = (
  error: Error,
): IFetchJobApplicationsFailure => ({
  type: "FETCH_JOB_APPLICATIONS_FAILURE",
  error,
})

export const invalidateJobApplicationsCache = (): IInvalidJobApplicationsCache => ({
  type: "INVALIDATE_JOB_APPLICATIONS_CACHE",
})

export const createApplication = (
  application: IPortalJobApplication,
  token: string,
  isDraft: boolean,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(createApplicationBegan())
    axios
      .post(
        `${getPortalApiURL()}/application?is-draft=${isDraft}`,
        portalJobApplicationToCreateApplicationRequest(application),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        dispatch(invalidateJobApplicationsCache())
        dispatch(createApplicationSuccess())
      })
      .catch(error => dispatch(createApplicationFailure(error)))
  }
}

export const fetchJobApplications = (
  token: string,
  cancelToken?: CancelTokenSource,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchJobApplicationsBegan())

    axios
      .get(`${getPortalApiURL()}/application`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken?.token,
      })
      .then(resp => {
        const jobApplications =
          normalize(resp.data, [NormalizedJobApplications]).entities
            .jobApplications || {}
        dispatch(fetchJobApplicationsSuccess(jobApplications))
      })
      .catch(err => dispatch(fetchJobApplicationsFailure(err)))
  }
}

const shouldFetchJobApplications = ({ jobApplicationReducer }): boolean => {
  return !jobApplicationReducer.validJobApplicationsCache
}

export const fetchJobApplicationsIfNeeded = (
  token: string,
  cancelToken?: CancelTokenSource,
) => {
  return async (dispatch, getState): Promise<void> => {
    if (shouldFetchJobApplications(getState())) {
      return dispatch(fetchJobApplications(token, cancelToken))
    }
    return Promise.resolve()
  }
}

export const setAlreadyApplied = (applied: boolean): ISetAlreadyApplied => ({
  type: "SET_ALREADY_APPLIED",
  applied,
})
