import cloneDeep from "lodash/cloneDeep"
import _ from "lodash"
import {
  ISearchFailure,
  ISearchSuccess,
  ISendTypeAheadSearchRequestSuccess,
  ISetCurrentPage,
  ISetFilters,
  ISetPageSize,
  ISetSearchQueries,
  ISetSearchQuery,
  ISetGeographicQuery,
  ISetSort,
  IToggleFilter,
  SearchActions,
  ISetCategoryFilterFromURL,
  ISetJobNotFoundAlert,
  setQueries,
  ISendTypeAheadGeographicSearchRequestSuccess,
  IChangeSlider,
} from "../actions/searchActions"
import { ISearchRequestFilters, ISort } from "../models/requests/SearchRequest"
import { IPage, IResult } from "../models/SearchResponse"

export interface IQuerySuggestion {
  documents: {}[]
}

interface IJobNotFoundAlert {
  open: boolean
  id: string
}

interface ISearchState {
  results: IResult[]
  error?: Error
  isLoading: boolean
  isFiltersSet: boolean
  searchQuery: string
  geographicQuery: string
  filters: ISearchRequestFilters
  facets: {}
  page: IPage
  sort: ISort
  isFetchingSearchQuerySuggestions: boolean
  isFetchingGeographicSearchQuerySuggestions: boolean
  searchQuerySuggestions: IQuerySuggestion
  geographicSearchQuerySuggestions: IQuerySuggestion
  jobNotFoundAlert: IJobNotFoundAlert
  sidebarSearch: boolean
}

export const initialState: ISearchState = {
  results: [],
  error: null,
  isLoading: true,
  isFiltersSet: false,
  searchQuery: "",
  geographicQuery: "",
  filters: {},
  facets: {},
  page: {
    current: 0,
    total_pages: 0,
    total_results: 0,
    size: 0,
  },
  sort: {
    sort: { open_date: "desc" },
    value: 2,
  },
  isFetchingSearchQuerySuggestions: false,
  isFetchingGeographicSearchQuerySuggestions: false,
  searchQuerySuggestions: {
    documents: [],
  },
  geographicSearchQuerySuggestions: {
    documents: [],
  },
  jobNotFoundAlert: {
    open: false,
    id: null,
  },
  sidebarSearch: true
}

function handleSearchBeganAction(state: ISearchState): ISearchState {
  return {
    ...state,
    isLoading: true,
    error: null,
  }
}

function handleSearchSuccessAction(
  state: ISearchState,
  action: ISearchSuccess,
): ISearchState {
  return {
    ...state,
    results: action.searchResponse.results,
    facets: action.searchResponse.facets,
    page: action.searchResponse.meta.page,
    isLoading: false,
    error: null,
  }
}

function handleSearchFailureAction(
  state: ISearchState,
  action: ISearchFailure,
): ISearchState {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  }
}

function handleSetFiltersAction(
  state: ISearchState,
  action: ISetFilters,
): ISearchState {
  return {
    ...state,
    isFiltersSet: true,
    filters: action.filters,
  }
}

function handleToggleFilterAction(
  state: ISearchState,
  action: IToggleFilter,
): ISearchState {
  const newPage = cloneDeep(state.page)
  newPage.current = 1
  const sidebarSearch = true
  const filters = cloneDeep(state.filters)
  const filterGroup = filters[action.filterGroup]

  if(action.filterGroup === "country_name"){

    let countryCount = 0
      Object.keys(filters.country_name).forEach(country => {
        if(filters.country_name[country].checked){
          countryCount += 1
        }
      })
    if(action.filter.value !== "USA" && countryCount > 0){
      Object.keys(filters.state_name).forEach(usState => {
        if(filters.state_name && filters.state_name[usState] && filters.state_name[usState].checked){
          filters.state_name[usState].checked = false
        }
      
      })
    }
  }
  if (filterGroup) {
    const filter = filterGroup[action.filter.value]

    if (filter) {
      filter.checked = !state.filters[action.filterGroup][action.filter.value]
        .checked
    }
  }

  return {
    ...state,
    filters,
    page: newPage,
    sidebarSearch
  }
}

function handleSetSearchQueriesAction(
  state: ISearchState,
  action: ISetSearchQueries,
): ISearchState {
  const filters = cloneDeep(state.filters)

  if(filters.job_req_area_name){
    Object.keys(filters.job_req_area_name).forEach(category => {
        if(filters.job_req_area_name[category].checked){
          filters.job_req_area_name[category].checked = false
        }
      })
  }

  if(filters.country_name){
    Object.keys(filters.country_name).forEach(country => {
        if(filters.country_name[country].checked){
          filters.country_name[country].checked = false
        }
      })
  }
  return {
    ...state,
    filters,
    searchQuery: action.titleQuery,
    geographicQuery: action.geographicQuery,
    page: {
      ...state.page,
      current: 1,
    },
  }
}

function handleSetSearchQueryAction(
  state: ISearchState,
  action: ISetSearchQuery,
): ISearchState {
  return {
    ...state,
    searchQuery: action.searchQuery,
  }
}

function handleSetGeographicQueryAction(
  state: ISearchState,
  action: ISetGeographicQuery,
): ISearchState {
  return {
    ...state,
    geographicQuery: action.geographicQuery,
  }
}

function handleSetCurrentPageAction(
  state: ISearchState,
  action: ISetCurrentPage,
): ISearchState {
  const newPage = cloneDeep(state.page)
  newPage.current = action.currentPage
  return {
    ...state,
    page: newPage,
  }
}

function handleSetPageSizeAction(
  state: ISearchState,
  action: ISetPageSize,
): ISearchState {
  const newPage = cloneDeep(state.page)
  newPage.size = action.size
  return {
    ...state,
    page: newPage,
  }
}

function handleClearFiltersAction(state: ISearchState): ISearchState {
  const uncheckedFilters = state.filters

  _.entries(uncheckedFilters).forEach(filter => {
    const [filterName, filterData] = filter

    if (filterName === "job_location_coords") {
      filterData.currentMin = filterData.min
      filterData.currentMax = filterData.max
    } else {
      _.values(filterData).forEach(subFilter => {
        // eslint-disable-next-line no-param-reassign
        subFilter.checked = false
      })
    }
  })

  return {
    ...state,
    filters: uncheckedFilters,
  }
}

function handleSetSortAction(
  state: ISearchState,
  action: ISetSort,
): ISearchState {
  return {
    ...state,
    sort: action.sort,
  }
}

function handleSendTypeAheadSearchRequestBeganAction(
  state: ISearchState,
): ISearchState {
  return {
    ...state,
    isFetchingSearchQuerySuggestions: true,
  }
}

function handleSendTypeAheadGeographicSearchRequestBeganAction(
  state: ISearchState,
): ISearchState {
  return {
    ...state,
    isFetchingGeographicSearchQuerySuggestions: true,
  }
}

function handleSendTypeAheadSearchRequestSuccessAction(
  state: ISearchState,
  action: ISendTypeAheadSearchRequestSuccess,
): ISearchState {
  return {
    ...state,
    isFetchingSearchQuerySuggestions: false,
    searchQuerySuggestions: action.results,
  }
}

function handleSendTypeAheadGeographicSearchRequestSuccessAction(
  state: ISearchState,
  action: ISendTypeAheadGeographicSearchRequestSuccess,
): ISearchState {
  return {
    ...state,
    isFetchingGeographicSearchQuerySuggestions: false,
    geographicSearchQuerySuggestions: action.results,
  }
}

function handleSendTypeAheadSearchRequestFailureAction(
  state: ISearchState,
): ISearchState {
  return {
    ...state,
    isFetchingSearchQuerySuggestions: false,
  }
}

function handleSendTypeAheadGeographicSearchRequestFailureAction(
  state: ISearchState,
): ISearchState {
  return {
    ...state,
    isFetchingGeographicSearchQuerySuggestions: false,
  }
}

function handleSetCategoryFilterFromURLAction(
  state: ISearchState,
  action: ISetCategoryFilterFromURL,
): ISearchState {
  let filterGroup = ""
  switch (action.category) {
    case 1:
      filterGroup = "Cleared Intelligence Professionals"
      break
    case 2:
      filterGroup = "Corporate Support"
      break
    case 3:
      filterGroup = "Cyber Security"
      break
    case 4:
      filterGroup = "Information Technology & Engineering"
      break
    case 5:
      filterGroup = "Language & Culture"
      break
    case 6:
      filterGroup = "Program Management"
      break
    case 7:
      filterGroup = "Specialized & Technical Expertise"
      break
    case 8:
      filterGroup = "Language Contractors"
      break
    // Any invalid job category in the URL will just trigger a search with no filters.
    default:
      return {
        ...state,
        filters: {},
      }
  }

  return {
    ...state,
    filters: {
      job_req_area_name: {
        [filterGroup]: { value: filterGroup, count: 0, checked: true },
      },
    },
  }
}

function handleSetJobNotFoundAlert(
  state: ISearchState,
  action: ISetJobNotFoundAlert,
): ISearchState {
  return {
    ...state,
    jobNotFoundAlert: {
      open: action.open,
      id: action.id,
    },
  }
}

function handleClearFullSearch(state: ISearchState): ISearchState {
  let newState = state

  newState = handleClearFiltersAction(state)
  newState = handleSetSearchQueriesAction(state, setQueries("", ""))

  return newState
}

function handleChangeSlider(
  action: IChangeSlider,
  state: ISearchState,
): ISearchState {
  const newPage = cloneDeep(state.page)
  newPage.current = 1

  let filters = cloneDeep(state.filters)

  filters = {
    ...filters,
    job_location_coords: {
      ...filters.job_location_coords,
      currentMin: action.range[0],
      currentMax: action.range[1],
    },
  }

  return {
    ...state,
    filters,
    page: newPage,
  }
}

function handleChangeSliderCommitted(state: ISearchState): ISearchState {
  return state
}

export function searchReducer(
  state = initialState,
  action: SearchActions,
): ISearchState {
  switch (action.type) {
    case "SET_SORT":
      return handleSetSortAction(state, action)
    case "SET_CURRENT_PAGE":
      return handleSetCurrentPageAction(state, action)
    case "SET_PAGE_SIZE":
      return handleSetPageSizeAction(state, action)
    case "SET_FILTERS":
      return handleSetFiltersAction(state, action)
    case "SEARCH_BEGAN":
      return handleSearchBeganAction(state)
    case "SEARCH_SUCCESS":
      return handleSearchSuccessAction(state, action)
    case "SEARCH_FAILURE":
      return handleSearchFailureAction(state, action)
    case "TOGGLE_FILTER":
      return handleToggleFilterAction(state, action)
    case "SET_SEARCH_QUERIES":
      return handleSetSearchQueriesAction(state, action)
    case "SET_SEARCH_QUERY":
      return handleSetSearchQueryAction(state, action)
    case "SET_GEOGRAPHIC_QUERY":
      return handleSetGeographicQueryAction(state, action)
    case "CLEAR_FILTERS":
      return handleClearFiltersAction(state)
    case "SEND_TYPE_AHEAD_SEARCH_REQUEST_BEGAN":
      return handleSendTypeAheadSearchRequestBeganAction(state)
    case "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_BEGAN":
      return handleSendTypeAheadGeographicSearchRequestBeganAction(state)
    case "SEND_TYPE_AHEAD_SEARCH_REQUEST_SUCCESS":
      return handleSendTypeAheadSearchRequestSuccessAction(state, action)
    case "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_SUCCESS":
      return handleSendTypeAheadGeographicSearchRequestSuccessAction(
        state,
        action,
      )
    case "SEND_TYPE_AHEAD_SEARCH_REQUEST_FAILURE":
      return handleSendTypeAheadSearchRequestFailureAction(state)
    case "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_FAILURE":
      return handleSendTypeAheadGeographicSearchRequestFailureAction(state)
    case "SET_CATEGORY_FILTER_FROM_URL":
      return handleSetCategoryFilterFromURLAction(state, action)
    case "SET_JOB_NOT_FOUND_ALERT":
      return handleSetJobNotFoundAlert(state, action)
    case "CLEAR_FULL_SEARCH":
      return handleClearFullSearch(state)
    case "CHANGE_SLIDER":
      return handleChangeSlider(action, state)
    case "CHANGE_SLIDER_COMMITTED":
      return handleChangeSliderCommitted(state)
    case "RESET_FULL_STATE":
      // Don't reset search state for logout request
      return state
    default:
      return state
  }
}
