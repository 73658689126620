import { createMuiTheme, MuiThemeProvider } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ConnectedRouter, routerMiddleware } from "connected-react-router"
import { loginMiddleware } from "haystack-auth"
import { createBrowserHistory } from "history"
import React from "react"
import { Provider } from "react-redux"
import { Route, Switch } from "react-router-dom"
import { Helmet } from "react-helmet"
import { applyMiddleware, compose, createStore } from "redux"
import { createLogger } from "redux-logger"
import { createEpicMiddleware } from "redux-observable"
import thunk from "redux-thunk"
import "./App.css"
import { applicantMiddleware } from "./middleware/ApplicantMiddleware"
import { createRootReducer, rootEpic } from "./reducers/store"
import AppLayout from "./views/App/AppLayout"
import ResetContainer from "./views/Reset/ResetContainer"
import {
  getApplicationTitle,
  shouldEnableReduxLogging,
} from "./constants/constants"

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#335c85",
      main: "#003366",
      dark: "#002952",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#bea768",
      main: "#ae9142",
      dark: "#8b7435",
      contrastText: "#000000",
    },
  },
  typography: {
    //  fontFamily: "Lato, sans-serif",
    fontFamily: "Overpass, sans-serif",
    h6: {
      fontFamily: "OverpassBlack, sans-serif",
      textTransform: "uppercase",
    },
    h5: {
      fontFamily: "OverpassBlack, sans-serif",
      textTransform: "uppercase",
    },
  },
  overrides: {
    MuiButton: {
      text: {
        fontFamily: "OverpassBlack, sans-serif",
      },
      label: {
        fontFamily: "OverpassBlack, sans-serif",
      },
    },
    MuiToolbar: {
      root: {
        height: 64,
      },
    },
  },
})

const logger = createLogger({
  diff: true,
  collapsed: () => true,
})

const epicMiddleware = createEpicMiddleware()
export const history = createBrowserHistory()

let store
if (shouldEnableReduxLogging()) {
  store = createStore(
    createRootReducer(history),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
        applyMiddleware(
          routerMiddleware(history),
          epicMiddleware,
          thunk,
          loginMiddleware,
          applicantMiddleware,
          logger,
        ),
      )) ||
      compose(
        applyMiddleware(
          routerMiddleware(history),
          epicMiddleware,
          thunk,
          loginMiddleware,
          applicantMiddleware,
          logger,
        ),
      ),
  )
} else {
  store = createStore(
    createRootReducer(history),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
        applyMiddleware(
          routerMiddleware(history),
          epicMiddleware,
          thunk,
          loginMiddleware,
          applicantMiddleware,
        ),
      )) ||
      compose(
        applyMiddleware(
          routerMiddleware(history),
          epicMiddleware,
          thunk,
          loginMiddleware,
          applicantMiddleware,
        ),
      ),
  )
}

epicMiddleware.run(rootEpic)

// TODO: Move out into a separate files and implement loaders
function NotFound() {
  return <div>Not Found</div>
}

function Unauthorized() {
  return <div>Unauthorized</div>
}

const App = () => {
  return (
    <>
      <Helmet>
        <title>{getApplicationTitle()}</title>
      </Helmet>
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route exact path="/404" component={NotFound} />
                <Route exact path="/401" component={Unauthorized} />
                <Route exact path="/reset" component={ResetContainer} />
                <Route
                  path="/"
                  name="Home"
                  render={() => <AppLayout history={history} />}
                />
              </Switch>
            </ConnectedRouter>
          </Provider>
        </CssBaseline>
      </MuiThemeProvider>
    </>
  )
}

export default App
