import _ from "lodash"
import {
  IPortalJobApplication,
  IPortalQuestionAnswer,
} from "../PortalJobApplication"

interface ICreateApplicationRequest {
  applicant: {
    id: number
  }
  jobRequisition: {
    id: number
  }
  id: number
  applicationAnswerList: {
    applicant: {
      id: number
    }
    jobApplication: {
      id: number
    }
    question: {
      id: number
    }
    selectedAnswers: {
      id: number
    }[]
    id: number
    answerText: string
  }[]
}

export function portalJobApplicationToCreateApplicationRequest(
  portalJobApplication: IPortalJobApplication,
): ICreateApplicationRequest {
  const createApplicationRequest: ICreateApplicationRequest = {
    applicationAnswerList: [],
    applicant: { id: portalJobApplication.applicantId },
    jobRequisition: {
      id: portalJobApplication.requisitionId,
    },
    id: portalJobApplication.id,
  }

  _.values(portalJobApplication.answers).forEach(group => {
    _.values(group).forEach(questionAnswer => {
      const questionAnswerCast = questionAnswer as IPortalQuestionAnswer
      const selectedAnswers = []
      if (questionAnswerCast.answerId) {
        selectedAnswers.push({
          id: questionAnswerCast.answerId,
        })
      }

      createApplicationRequest.applicationAnswerList.push({
        applicant: {
          id: portalJobApplication.applicantId,
        },
        jobApplication: {
          id: portalJobApplication.requisitionId,
        },
        question: {
          id: questionAnswerCast.questionWrapper,
        },
        id: questionAnswerCast.id,
        selectedAnswers,
        answerText: questionAnswerCast.answerText,
      })
    })
  })

  return createApplicationRequest
}
