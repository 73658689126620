import axios, { CancelTokenSource } from "axios"
import { GeneralActions, IResizeViewport } from "../actions/generalActions"

const cancelToken = axios.CancelToken

interface IGeneralState {
  isMobile: boolean
  size: number
  cancelToken: CancelTokenSource
}

export const initialState: IGeneralState = {
  isMobile: window.innerWidth <= 500,
  size: window.innerWidth,
  cancelToken: cancelToken.source(),
}

function handleResizeViewport(
  state: IGeneralState,
  action: IResizeViewport,
): IGeneralState {
  return {
    ...state,
    isMobile: action.size <= 500,
    size: action.size,
  }
}

function handleLoginSuccess(state: IGeneralState): IGeneralState {
  return {
    ...state,
    cancelToken: cancelToken.source(),
  }
}

function handleLogoutRequest(state: IGeneralState): IGeneralState {
  state.cancelToken.cancel("logout")

  return state
}

export function general(
  state = initialState,
  action: GeneralActions,
): IGeneralState {
  switch (action.type) {
    case "RESIZE_VIEWPORT":
      return handleResizeViewport(state, action)
    case "LOGIN_SUCCESS":
      return handleLoginSuccess(state)
    case "LOGOUT_REQUEST":
      return handleLogoutRequest(state)
    case "RESET_FULL_STATE":
      // Don't reset any general state for a logout
      return state
    default:
      return state
  }
}
