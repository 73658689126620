import { connectRouter } from "connected-react-router"
import { loginReducer } from "haystack-auth"
import { combineReducers } from "redux"
import { combineEpics } from "redux-observable"
import {
  handleApplicantMergeActions,
  handleDoesApplicantExistActions,
  handleLoginAction,
  handleSaveJobSuccess,
  handleDeleteJobSuccess,
  handleCreateOrUpdateApplicantSuccessAction,
} from "../actions/applicantActions"
import {
  handleSearchBeganAction,
  handleSearchPageStateMutation,
  handleSearchSuccessAction,
} from "../actions/searchActions"
import { applicantReducer } from "./applicantReducer"
import { basicDataReducer } from "./basicDataReducer"
import { jobApplicationReducer } from "./jobApplicationReducers"
import { jobRequisitionReducer } from "./jobRequisitionReducer"
import { searchReducer } from "./searchReducer"
import { sidebarReducer } from "./sidebarReducer"
import { general } from "./general"

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    loginReducer,
    applicantReducer,
    jobRequisitionReducer,
    jobApplicationReducer,
    sidebarReducer,
    searchReducer,
    basicDataReducer,
    general,
  })

export const rootEpic = combineEpics(
  handleLoginAction,
  handleDoesApplicantExistActions,
  handleSearchBeganAction,
  handleSearchPageStateMutation,
  handleSearchSuccessAction,
  handleSaveJobSuccess,
  handleDeleteJobSuccess,
  handleApplicantMergeActions,
  handleCreateOrUpdateApplicantSuccessAction,
)
