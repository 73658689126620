import _ from "lodash"
import {
  ISearchResponseFacets,
  IFacetValueData,
  IFacetRangeData,
} from "../SearchResponse"

export interface ISearchRequest {
  query: string
  facets: ISearchRequestFacets
  page: {
    size: number
    current?: number
  }
  filters: ISearchRequestFilters
  sort: ISort
}

export interface ISort {
  sort: {
    [key: string]: string
  }
  value: number
}

export interface ISearchRequestFacets {
  [key: string]: {
    type: string
    [x: string]: any
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISearchRequestFilters {
  // [key: string]: string[]
}

export function searchResponseFacetsToPortalFilters(
  facets: ISearchResponseFacets,
  current: any = {},
  state
): ISearchRequestFilters {
  const filters = {}

  _.keys(facets).forEach((facetName: string) => {
    const { type, data } = facets[facetName][0]

    switch (type.toLowerCase()) {
      case "range": {
        const filterValues = _.values(data)

        filterValues.forEach(f => {
          if (f.to === undefined) {
            // eslint-disable-next-line no-param-reassign
            f.to = Infinity
          }
        })

        const min = _.minBy(filterValues, "from").from
        const max = _.maxBy(filterValues, "to").to

        filters[facetName] = {
          currentMin: _.get(current, "job_location_coords.currentMin", min),
          currentMax: _.get(
            current,
            "job_location_coords.currentMax",
            max >= 200 ? 200 : max,
          ),
          min,
          max: max >= 200 ? 200 : max,
        }

        break
      }
      case "value":
      default: {
        if ((current[facetName] && facetName === "job_req_area_name") ||
              current[facetName] && facetName === "country_name") {
                let flag = false
                  _.keys(current[facetName]).forEach(f => {
                    if(current[facetName][f].checked){
                      flag = true
                    }
                  })

                if(!flag || !state.value.searchReducer.sidebarSearch){
                  data.forEach((facet: IFacetRangeData | IFacetValueData) => {
                    let filter = null

                    const valueFacet = facet as IFacetValueData

                    filter = {
                      type,
                      value: valueFacet.value,
                      count: valueFacet.count,
                    }

                    filter.checked =
                      current[facetName]?.[filter.value]?.checked || false

                    if (!(facetName in filters)) filters[facetName] = {}

                    filters[facetName][filter.value] = filter
                  })
                }else if(state.value.searchReducer.sidebarSearch ){

                      _.keys(current[facetName]).forEach(f => {
                      let filter = null

                       filter = {
                        type,
                        value: current[facetName][f].value,
                        count: current[facetName][f].count,
                      }

                      const categoryData = _.values(data)
                      const category = categoryData.find(obj => {
                        return obj.value === filter.value
                      })
                      filter.checked = false
                      if (category != null && current[facetName][f].checked) {
                        filter.checked = true
                      }

                      if (!(facetName in filters)) filters[facetName] = {}
                      filters[facetName][filter.value] = filter
                    })
                    if(facetName === "job_req_area_name"){
                      // eslint-disable-next-line no-param-reassign
                      state.value.searchReducer.sidebarSearch = false
                    }
                }
        } else {
          
          data.forEach((facet: IFacetRangeData | IFacetValueData) => {
            let filter = null

            const valueFacet = facet as IFacetValueData

            filter = {
              type,
              value: valueFacet.value,
              count: valueFacet.count,
            }

            filter.checked =
              current[facetName]?.[filter.value]?.checked || false

            if (!(facetName in filters)) filters[facetName] = {}

            filters[facetName][filter.value] = filter
          })
        }
      }
    }
  })

  return filters
}
