import {
  createStyles,
  Menu,
  MenuItem,
  withStyles,
  ListItemIcon,
  Typography,
  AppBar,
  IconButton,
  Button,
  Toolbar,
} from "@material-ui/core"
import { fade } from "@material-ui/core/styles"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import DescriptionIcon from "@material-ui/icons/Description"
import WorkIcon from "@material-ui/icons/Work"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import Logo from "../../assets/Mission_Essential_Logo_Inline_BLU.png"
import { authConfig } from "../../constants/constants"
import PortalDetailBar from "./PortalDetailBar"

const styles = ({
  palette,
  spacing,
  shape,
  transitions,
  breakpoints,
  zIndex,
}) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
    },
    grow: {
      flexGrow: 1,
    },
    header: {
      justifyContent: "space-between",
    },
    menuButton: {
      marginRight: spacing(2),
    },
    title: {
      cursor: "pointer",
    },
    logo: {
      maxWidth: "350px",
      minWidth: "100px",
      width: "100%",
      marginTop: 4,
    },
    login: {
      fontFamily: "Overpass",
      color: "#003366",
    },
    appBar: {
      background: "white",
      zIndex: zIndex.drawer + 1,
    },
    search: {
      position: "relative",
      borderRadius: shape.borderRadius,
      backgroundColor: fade(palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(palette.common.white, 0.25),
      },
      marginRight: spacing(2),
      marginLeft: 0,
    },
    searchButton: {
      height: "100%",
    },
    searchIcon: {
      width: spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      padding: spacing(1, 1, 1, 7),
      transition: transitions.create("width"),
      width: "100%",
    },
    drawer: {
      [breakpoints.up("sm")]: {
        width: 260,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: 260,
    },
  })

class PortalAppBar extends Component {
  constructor() {
    super()
    this.state = {}
  }

  handleAccountButtonClick = event => {
    this.setState({ accountAnchor: event.currentTarget })
  }

  handleApplicationsButtonClick = () => {
    const { history } = this.props

    this.handleClose()
    history.push("/applications")
  }

  handleSavedJobsButtonClick = () => {
    const { history } = this.props

    this.handleClose()
    history.push("/saved-jobs")
  }

  handleClose = () => {
    this.setState({ accountAnchor: null })
  }

  handleLogoKeyDown = e => {
    if (e.keyCode === 13) {
      this.handleLogoClick()
    }
  }

  handleLogoClick = () => {
    const { clearFullSearch, history } = this.props

    clearFullSearch()
    history.push("/search")
  }

  renderProfileIcon() {
    return (
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={this.handleAccountButtonClick}
      >
        <AccountCircleIcon />
      </IconButton>
    )
  }

  render() {
    const { classes, isAuthenticated, login, logout, history } = this.props
    const { accountAnchor } = this.state

    return (
      <div>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.header}>
            <div
              className={classes.title}
              onClick={this.handleLogoClick}
              onKeyDown={this.handleLogoKeyDown}
              role="link"
              tabIndex={0}
            >
              <img src={Logo} alt="logo" className={classes.logo} />
            </div>
            {isAuthenticated ? (
              this.renderProfileIcon()
            ) : (
              <Button
                color="primary"
                className={classes.login}
                onClick={() => {
                  login(authConfig)
                }}
              >
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>

        <PortalDetailBar />
        <Menu
          id="simple-menu"
          anchorEl={accountAnchor}
          keepMounted
          open={Boolean(accountAnchor)}
          onClose={this.handleClose}
        >
          <MenuItem
            onClick={() => {
              this.handleClose()
              history.push("/profile")
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <Typography>Profile</Typography>
          </MenuItem>
          <MenuItem onClick={this.handleApplicationsButtonClick}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <Typography>Applications</Typography>
          </MenuItem>
          <MenuItem onClick={this.handleSavedJobsButtonClick}>
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <Typography>Saved Positions</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              logout()
              this.handleClose()
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <Typography>Logout</Typography>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(PortalAppBar))
