import { AppBar, createStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import withStyles from "@material-ui/core/styles/withStyles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import MenuIcon from "@material-ui/icons/Menu"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, withRouter } from "react-router-dom"
import { toggleSidebar } from "../../actions/sidebarActions"
import SearchRow from "./SearchRow"

const styles = theme =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })

class PortalDetailBar extends Component {
  constructor(props) {
    super(props)

    this.renderJobLabel = this.renderJobLabel.bind(this)
    this.renderBackToJobButton = this.renderBackToJobButton.bind(this)
    this.renderProfileLabel = this.renderProfileLabel.bind(this)
  }

  handleRedirect = () => {
    const { history } = this.props

    history.push("/search")
  }

  handleBackToJobRedirect = () => {
    const { history } = this.props

    history.goBack()
  }

  renderProfileLabel() {
    const { handleToggleSidebar } = this.props

    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleToggleSidebar}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Grid>
        <Grid item>
          <Typography variant="h6">Profile</Typography>
        </Grid>
      </Grid>
    )
  }

  renderJobLabel() {
    return (
      <Button
        startIcon={<ArrowBackIcon />}
        color="inherit"
        onClick={this.handleRedirect}
      >
        Back to Search
      </Button>
    )
  }

  renderBackToJobButton() {
    return (
      <Button
        startIcon={<ArrowBackIcon />}
        color="inherit"
        onClick={this.handleBackToJobRedirect}
      >
        Back to Job
      </Button>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <AppBar
        style={{ background: "#666666", marginTop: 64 }}
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <Route path="/job/:id" exact component={this.renderJobLabel} />
          <Route
            path="/job/:id/apply"
            exact
            component={this.renderBackToJobButton}
          />
          <Route path="/profile" component={this.renderProfileLabel} />
          <div className={classes.grow} />
          <Route path="/search" component={SearchRow} />
        </Toolbar>
      </AppBar>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  handleToggleSidebar: () => dispatch(toggleSidebar()),
})

const mapStateToProps = state => ({
  searchResponse: state.searchReducer.searchResponse,
  searchQuery: state.searchReducer.searchQuery,
  filters: state.searchReducer.filter,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(withRouter(PortalDetailBar)))
