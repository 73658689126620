import axios from "axios"
import cloneDeep from "lodash/cloneDeep"
import { ofType } from "redux-observable"
import { map, mergeMap } from "rxjs/operators"
import _ from "lodash"
import moment from "moment"
import { getPortalApiURL, portalFacets } from "../constants/constants"
import { IFilter } from "../models/Filter"
import {
  ISearchRequestFilters,
  searchResponseFacetsToPortalFilters,
  ISort,
} from "../models/requests/SearchRequest"
import {
  ISearchResponse,
  IFacetRangeData,
  IFacetValueData,
} from "../models/SearchResponse"
import { IQuerySuggestion } from "../reducers/searchReducer"
import { IResetFullState } from "./generalActions"

export interface ISearchBegan {
  type: "SEARCH_BEGAN"
}

export interface ISearchSuccess {
  type: "SEARCH_SUCCESS"
  searchResponse: ISearchResponse
}

export interface ISearchFailure {
  type: "SEARCH_FAILURE"
  error: Error
}

export interface IToggleFilter {
  type: "TOGGLE_FILTER"
  filter: IFilter
  filterGroup: string
}

export interface ISetFilters {
  type: "SET_FILTERS"
  filters: ISearchRequestFilters
}

export interface ISetSearchQueries {
  type: "SET_SEARCH_QUERIES"
  titleQuery: string
  geographicQuery: string
}

export interface ISetGeographicQuery {
  type: "SET_GEOGRAPHIC_QUERY"
  geographicQuery: string
}

export interface ISetSearchQuery {
  type: "SET_SEARCH_QUERY"
  searchQuery: string
}

export interface ISetCurrentPage {
  type: "SET_CURRENT_PAGE"
  currentPage: number
}

export interface ISetPageSize {
  type: "SET_PAGE_SIZE"
  size: number
}

export interface ISetSort {
  type: "SET_SORT"
  sort: ISort
}

export interface IClearFilters {
  type: "CLEAR_FILTERS"
}

export interface ISendTypeAheadSearchRequestBegan {
  type: "SEND_TYPE_AHEAD_SEARCH_REQUEST_BEGAN"
}

export interface ISendTypeAheadSearchRequestSuccess {
  type: "SEND_TYPE_AHEAD_SEARCH_REQUEST_SUCCESS"
  results: IQuerySuggestion
}

export interface ISendTypeAheadSearchRequestFailure {
  type: "SEND_TYPE_AHEAD_SEARCH_REQUEST_FAILURE"
}

export interface ISendTypeAheadGeographicSearchRequestBegan {
  type: "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_BEGAN"
}

export interface ISendTypeAheadGeographicSearchRequestSuccess {
  type: "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_SUCCESS"
  results: IQuerySuggestion
}

export interface ISendTypeAheadGeographicSearchRequestFailure {
  type: "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_FAILURE"
}

export interface ISetCategoryFilterFromURL {
  type: "SET_CATEGORY_FILTER_FROM_URL"
  category: number
}

export interface ISetJobNotFoundAlert {
  type: "SET_JOB_NOT_FOUND_ALERT"
  open: boolean
  id: string
}

export interface IClearFullSearch {
  type: "CLEAR_FULL_SEARCH"
}

interface ILocation {
  lat: number
  lng: number
}

interface IGeometry {
  location: ILocation
}

interface IAddressToCoordsResponseResult {
  geometry: IGeometry
}

export interface IAddressToCoordsResponse {
  results: IAddressToCoordsResponseResult[]
}

export interface IChangeSlider {
  type: "CHANGE_SLIDER"
  range: number[]
}

export interface IChangeSliderCommitted {
  type: "CHANGE_SLIDER_COMMITTED"
}

export type SearchActions =
  | ISearchBegan
  | ISearchSuccess
  | ISearchFailure
  | ISendTypeAheadSearchRequestBegan
  | ISendTypeAheadGeographicSearchRequestBegan
  | ISendTypeAheadSearchRequestSuccess
  | ISendTypeAheadGeographicSearchRequestSuccess
  | ISendTypeAheadSearchRequestFailure
  | ISendTypeAheadGeographicSearchRequestFailure
  | ISetFilters
  | ISetSearchQueries
  | ISetSearchQuery
  | ISetGeographicQuery
  | ISetCurrentPage
  | ISetPageSize
  | IToggleFilter
  | ISetSort
  | IClearFilters
  | ISetCategoryFilterFromURL
  | ISetJobNotFoundAlert
  | IClearFullSearch
  | IResetFullState
  | IChangeSlider
  | IChangeSliderCommitted

export const searchBegan = (): ISearchBegan => ({
  type: "SEARCH_BEGAN",
})

export const searchSuccess = (
  searchResponse: ISearchResponse,
): ISearchSuccess => ({
  type: "SEARCH_SUCCESS",
  searchResponse,
})

export const searchFailure = (error: Error): ISearchFailure => ({
  type: "SEARCH_FAILURE",
  error,
})

export const setFilters = (filters: ISearchRequestFilters): ISetFilters => ({
  type: "SET_FILTERS",
  filters,
})

export const toggleFilter = (
  filter: IFilter,
  filterGroup: string,
): IToggleFilter => ({
  type: "TOGGLE_FILTER",
  filter,
  filterGroup,
})

export const setQueries = (
  titleQuery: string,
  geographicQuery,
): ISetSearchQueries => ({
  type: "SET_SEARCH_QUERIES",
  titleQuery,
  geographicQuery,
})

export const setSearchQuery = (searchQuery: string): ISetSearchQuery => ({
  type: "SET_SEARCH_QUERY",
  searchQuery,
})

export const setGeographicQuery = (
  geographicQuery: string,
): ISetGeographicQuery => ({
  type: "SET_GEOGRAPHIC_QUERY",
  geographicQuery,
})

export const setCurrentPage = (currentPage: number): ISetCurrentPage => ({
  type: "SET_CURRENT_PAGE",
  currentPage,
})

export const setPageSize = (size: number): ISetPageSize => ({
  type: "SET_PAGE_SIZE",
  size,
})

export const setSort = (sort: ISort): ISetSort => ({
  type: "SET_SORT",
  sort,
})

export const clearFilters = (): IClearFilters => ({
  type: "CLEAR_FILTERS",
})

export const sendTypeAheadSearchRequestBegan = (): ISendTypeAheadSearchRequestBegan => ({
  type: "SEND_TYPE_AHEAD_SEARCH_REQUEST_BEGAN",
})

export const sendTypeAheadGeographicSearchRequestBegan = (): ISendTypeAheadGeographicSearchRequestBegan => ({
  type: "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_BEGAN",
})

export const sendTypeAheadSearchRequestSuccess = (
  results,
): ISendTypeAheadSearchRequestSuccess => ({
  type: "SEND_TYPE_AHEAD_SEARCH_REQUEST_SUCCESS",
  results,
})

export const sendTypeAheadGeographicSearchRequestSuccess = (
  results,
): ISendTypeAheadGeographicSearchRequestSuccess => ({
  type: "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_SUCCESS",
  results,
})

export const sendTypeAheadSearchRequestFailure = (): ISendTypeAheadSearchRequestFailure => ({
  type: "SEND_TYPE_AHEAD_SEARCH_REQUEST_FAILURE",
})

export const sendTypeAheadGeographicSearchRequestFailure = (): ISendTypeAheadGeographicSearchRequestFailure => ({
  type: "SEND_TYPE_AHEAD_GEOGRAPHIC_SEARCH_REQUEST_FAILURE",
})

export const setJobNotFoundAlert = (
  open: boolean,
  id: string,
): ISetJobNotFoundAlert => ({
  type: "SET_JOB_NOT_FOUND_ALERT",
  open,
  id,
})

export const setCategoryFilterFromURL = (
  category: number,
): ISetCategoryFilterFromURL => ({
  type: "SET_CATEGORY_FILTER_FROM_URL",
  category,
})

export const changeSlider = (range: number[]): IChangeSlider => ({
  type: "CHANGE_SLIDER",
  range,
})

export const changeSliderCommitted = (): IChangeSliderCommitted => ({
  type: "CHANGE_SLIDER_COMMITTED",
})

export const clearFullSearch = (): IClearFullSearch => ({
  type: "CLEAR_FULL_SEARCH",
})

export const fetchSearchQuerySuggestions = query => {
  return async (dispatch): Promise<void> => {
    dispatch(sendTypeAheadSearchRequestBegan())
    axios
      .post(`${getPortalApiURL()}/search/query-suggestion`, {
        query,
        size: 10,
        types: {
          documents: {
            fields: ["name"],
          },
        },
      })
      .then(res =>
        dispatch(
          sendTypeAheadSearchRequestSuccess(
            _.get(res, "data.results", { documents: [] }),
          ),
        ),
      )
      .catch(() => dispatch(sendTypeAheadSearchRequestFailure()))
  }
}

export const fetchGeographicSearchQuerySuggestions = query => {
  return async (dispatch): Promise<void> => {
    dispatch(sendTypeAheadGeographicSearchRequestBegan())
    axios
      .post(`${getPortalApiURL()}/search/query-suggestion`, {
        query,
        size: 10,
        types: {
          documents: {
            fields: ["job_location_description"],
          },
        },
      })
      .then(res =>
        dispatch(
          sendTypeAheadGeographicSearchRequestSuccess(
            _.get(res, "data.results", { documents: [] }),
          ),
        ),
      )
      .catch(() => dispatch(sendTypeAheadGeographicSearchRequestFailure()))
  }
}

const transformSearchRequest = (searchRequest): {} => {
  const newRequest = cloneDeep(searchRequest)

  newRequest.filters = { all: [] }

  Object.entries(searchRequest.filters).forEach(value => {
    const filter = {}
    const [first, second] = value

    filter[first] = second

    newRequest.filters.all.push(filter)
  })

  if (newRequest.page.current <= 0) {
    delete newRequest.page.current
  }

  if (newRequest.page.size <= 0) {
    delete newRequest.page.size
  }

  if (_.keys(newRequest.page).length <= 0) {
    delete newRequest.page
  }

  if (_.isEmpty(newRequest.sort.sort)) {
    delete newRequest.sort
  } else {
    newRequest.sort = searchRequest.sort.sort
  }

  if (newRequest.query !== "") newRequest.query = `"${newRequest.query}"`

  return newRequest
}

export const handleSearchBeganAction = (action$): void =>
  action$.pipe(
    ofType(
      "TOGGLE_FILTER",
      "CHANGE_SLIDER_COMMITTED",
      "SET_SEARCH_QUERIES",
      "SET_CURRENT_PAGE",
      "SET_PAGE_SIZE",
      "SET_SORT",
      "CLEAR_FILTERS",
      "CLEAR_FULL_SEARCH",
    ),
    map(() => searchBegan()),
  )

const portalFiltersToSearchRequestFilters = (
  portalFilters: IPortalFilters,
  coords: string,
): ISearchRequestFilters => {
  const searchRequestFilters: ISearchRequestFilters = {}

  // Iterate through high-level filters (dropdowns in filter sidebar)
  _.keys(portalFilters).forEach(filterKey => {
    const filter: IPortalFilters | any = portalFilters[filterKey]

    if (filterKey === "job_location_coords" && coords.trim() !== "") {
      const rangeFilter: any = {
        center: coords,
        unit: "mi",
        from: filter.currentMin,
      }

      if (filter.currentMax < 200) {
        rangeFilter.to = filter.currentMax
      }

      searchRequestFilters[filterKey] = rangeFilter
    } else {
      // Iterate through subfilters (values in the dropdowns in filter sidebar)
      _.keys(filter).forEach(() => {
        searchRequestFilters[filterKey] = _.values(filter)
          .filter(f => f.checked)
          .map(f => f.value)
      })
    }

    // If the filter is a value filter and doesn't have any values,
    // remove it from the searchRequestFilters
    if (_.isArray(searchRequestFilters[filterKey])) {
      if (searchRequestFilters[filterKey].length <= 0) {
        delete searchRequestFilters[filterKey]
      }
    }
  })

  return searchRequestFilters
}

interface ICoords {
  latitude: number
  longitude: number
  cachedOn: string
}

// Promise wrapper for getting user's geolocation from the browser
const getPosition = (options?): Promise<ICoords> => {
  return new Promise((resolve, reject) => {
    // If the geolocation API is supported on this browser
    if (navigator.geolocation) {
      // Try and get the cached geolocation from localStorage and use that
      let cachedLocation: ICoords = null
      try {
        cachedLocation = JSON.parse(localStorage.getItem("geolocation"))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      let isExpired = true
      if (cachedLocation) {
        isExpired = moment()
          .subtract(1, "hour")
          .isAfter(moment(cachedLocation.cachedOn))
      }

      // If the location exists in localStorage and isn't stale, resolve
      // with the location
      if (cachedLocation && !isExpired) {
        resolve(cachedLocation)
        // Otherwise, ask the user for permission to get location and on success,
        // cache the location in localStorage and resolve
      } else {
        //  gateCheck is a bit to see if navigator.geolocation fails for an undetermined reason.
        //  If navigator.geolocation succeeds, or an error is determined, gateCheck is set to false.
        let gateCheck = true

        navigator.geolocation.getCurrentPosition(
          position => {
            const coords: ICoords = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              cachedOn: moment().toISOString(),
            }
            gateCheck = false
            localStorage.setItem("geolocation", JSON.stringify(coords))
            resolve(coords)
          },
          error => {
            gateCheck = false
            localStorage.removeItem("geolocation")
            reject(error)
          },
          options,
        )
        
        //  This timer is to allow a max execution time of 1s for navigator.geolocation to succeed or return an error.
        //  If navigator.geolocation does not succeed/error; then gateCheck is still true, so Timeout will run and resolve with rejection.
        setTimeout(()=>{
          if(gateCheck){
            reject(new Error("Location Access Blocked"))
          }
        }, 1000)


      }
    } else {
      localStorage.removeItem("geolocation")
      reject()
    }
  })
}

export const handleSearchPageStateMutation = (action$, state$): void =>
  action$.pipe(
    ofType("SEARCH_BEGAN"),
    mergeMap(async () => {
      const { geographicQuery } = state$.value.searchReducer

      let coords = ""

      // If there is a geographic search term, get the coordinates for the term
      // and update coords
      if (geographicQuery) {
        try {
          const res = await axios({
            method: "GET",
            url: `${getPortalApiURL()}/search/coords?address=${geographicQuery}`,
          })

          const location = _.get(
            res,
            "data.resourceSets[0].resources[0].point.coordinates",
          )

          if (location) {
            coords = `${location[0]}, ${location[1]}`
          } else {
            console.log("else block")
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
        // If there is no geographic search term, attempt to get the user's position
        // from the geolocation API and update coords
      } else if (state$.value.searchReducer.filters.country_name) {
        try {
          const position = await getPosition()

          coords = `${position.latitude}, ${position.longitude}`
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      } else {
        console.log("No value")
      }

      return axios
        .post(
          `${getPortalApiURL()}/search`,
          transformSearchRequest({
            // Pass coords to portalFacets and if coords is truthy it will include
            // the coords in the appsearch request body
            facets: portalFacets(coords),
            filters: portalFiltersToSearchRequestFilters(
              state$.value.searchReducer.filters,
              coords,
            ),
            page: {
              size: state$.value.searchReducer.page.size,
              current: state$.value.searchReducer.page.current,
            },
            query: state$.value.searchReducer.searchQuery,
            sort: state$.value.searchReducer.sort,
          }),
        )
        .then(searchRequestJSON => searchSuccess(searchRequestJSON.data))
        .catch(err => searchFailure(err))
    }),
  )

export const handleSearchSuccessAction = (action$, state$): void =>
  action$.pipe(
    ofType("SEARCH_SUCCESS"),
    map(() =>
      setFilters(
        searchResponseFacetsToPortalFilters(
          state$.value.searchReducer.facets,
          state$.value.searchReducer.filters,
          state$
        ),
      ),
    ),
  )

interface IPortalFilters {
  [key: string]: IPortalSubFilter
}

interface IPortalSubFilter {
  [filter: string]: IFacetRangeData | IFacetValueData
}
