import {
  FETCH_JOB_REQUISITION_BEGAN,
  FETCH_JOB_REQUISITION_FAILURE,
  FETCH_JOB_REQUISITION_SUCCESS,
  RESET_FULL_STATE,
} from "../actions/jobRequisitionActions"

export const initialState = {
  jobRequisition: undefined,
  isLoading: true,
  error: undefined,
}

function handleFetchJobRequisitionBeganAction(state) {
  return {
    ...state,
    isLoading: true,
    error: null,
  }
}

function handleFetchJobRequisitionSuccessAction(state, action) {
  return {
    error: null,
    isLoading: false,
    jobRequisition: action.jobRequisition,
  }
}

function handleFetchJobRequisitionFailureAction(state, action) {
  return {
    error: action.error,
    isLoading: false,
    jobRequisition: null,
  }
}

export function jobRequisitionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_JOB_REQUISITION_BEGAN:
      return handleFetchJobRequisitionBeganAction(state)
    case FETCH_JOB_REQUISITION_FAILURE:
      return handleFetchJobRequisitionFailureAction(state, action)
    case FETCH_JOB_REQUISITION_SUCCESS:
      return handleFetchJobRequisitionSuccessAction(state, action)
    case RESET_FULL_STATE:
      // Don't reset job requisition state since job requisitions will be the same for all applicants
      return state
    default:
      return state
  }
}
