import Box from "@material-ui/core/Box"
import React from "react"

function PortalFooter() {
  return (
    <Box
      width="100%"
      marginBottom={1}
      paddingLeft={1}
      paddingRight={1}
      marginTop={1}
      display="flex"
      justifyContent="center"
    />
  )
}

export default PortalFooter
