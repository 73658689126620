import { Component } from "react"
import { withRouter } from "react-router-dom"
import { unauthenticatedRedirectPath } from "../constants/constants"

class HaystackRedirect extends Component {
  componentDidMount() {
    const { history } = this.props
    history.push(unauthenticatedRedirectPath)
  }

  render = () => null
}

export default withRouter(HaystackRedirect)
