import { createStyles, withStyles } from "@material-ui/core"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"
import React, { Component } from "react"
import { withRouter } from "react-router"
import PortalFooter from "../../components/PortalFooter"

const styles = theme =>
  createStyles({
    container: {
      margin: theme.spacing(2),
      minHeight: "calc(100vh - 128px - 60px)",
    },
  })

const sideBarRoutes = ["/search", "/profile"]

class AppContainer extends Component {
  isMobileSize() {
    const { width } = this.props
    return isWidthDown("xs", width)
  }

  render() {
    const { location, children, classes } = this.props

    const doesHaveSidebar =
      sideBarRoutes.includes(location.pathname) && !this.isMobileSize()
        ? { marginLeft: 16 + 260 }
        : { marginLeft: 16 }

    return (
      <>
        <div className={classes.container} style={doesHaveSidebar}>
          {children}
        </div>
        <PortalFooter />
      </>
    )
  }
}

export default withRouter(withStyles(styles)(withWidth()(AppContainer)))
