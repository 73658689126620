import axios, { CancelTokenSource } from "axios"
import { push } from "connected-react-router"
import { normalize } from "normalizr"
import { Dispatch, Action } from "redux"
import {
  ILogoutRequest,
  ILoginSuccess,
} from "haystack-auth/src/actions/loginActions"
import { ofType } from "redux-observable"
import { filter, map, mergeMap } from "rxjs/operators"
import { Observable } from "rxjs"
import { getPortalApiURL } from "../constants/constants"
import { IApplicant } from "../models/Applicant"
import { IJobRequisition } from "../models/JobRequisition"
import { NormalizedSavedJobs } from "../models/NormalizerSchema"
import { fetchJobApplicationsIfNeeded } from "./jobApplicationActions"
import { IResetFullState } from "./generalActions"

export interface IFetchApplicantBegan {
  type: "FETCH_APPLICANT_BEGAN"
}

export interface IFetchApplicantSuccess {
  type: "FETCH_APPLICANT_SUCCESS"
  applicant: IApplicant
}

export interface IFetchApplicantError {
  type: "FETCH_APPLICANT_ERROR"
  error: Error
}

export interface ICreateOrUpdateApplicantBegan {
  type: "UPDATE_APPLICANT_BEGAN"
}

export interface ICreateOrUpdateApplicantSuccess {
  type: "UPDATE_APPLICANT_SUCCESS"
  applicant: IApplicant
}

export interface IDoesApplicantExistBegan {
  type: "DOES_APPLICANT_EXIST_BEGAN"
}

export interface IDoesApplicantExistSuccess {
  type: "DOES_APPLICANT_EXIST_SUCCESS"
  doesExist: boolean
}

export interface IDoesApplicantExistFailure {
  type: "DOES_APPLICANT_EXIST_ERROR"
  error: Error
}

export interface ICreateOrUpdateApplicantFailure {
  type: "UPDATE_APPLICANT_FAILURE"
  error: Error
}

export interface ISetApplicantJsonPathValue {
  type: "SET_APPLICANT_JSON_PATH_VALUE"
  path: string
  value: any
}

export interface IAddAdditionalCollectionElement {
  type: "ADD_ADDITIONAL_COLLECTION_ELEMENT"
  path: string
}

export interface IRemoveCollectionElement {
  type: "REMOVE_COLLECTION_ELEMENT"
  path: string
  index: number
}

export interface ISaveJobBegan {
  type: "SAVE_JOB_BEGAN"
}

export interface IDeleteSavedJobBegan {
  type: "DELETE_SAVED_JOB_BEGAN"
}

export interface ISaveJobSuccess {
  type: "SAVE_JOB_SUCCESS"
  jobReqId: number
}

export interface IDeleteSavedJobSuccess {
  type: "DELETE_SAVED_JOB_SUCCESS"
  jobReqId: number
}

export interface ISaveJobFailure {
  type: "SAVE_JOB_FAILURE"
  error: Error
}

export interface IDeleteSavedJobFailure {
  type: "DELETE_SAVED_JOB_FAILURE"
  error: Error
}

export interface IPushSavedJob {
  type: "PUSH_SAVED_JOB"
  jobReqId: number
}

export interface IPopDeletedSavedJob {
  type: "POP_DELETED_SAVED_JOB"
  jobReqId: number
}

export interface IFetchSavedJobsBegan {
  type: "FETCH_SAVED_JOBS_BEGAN"
}

export interface IFetchSavedJobsSuccess {
  type: "FETCH_SAVED_JOBS_SUCCESS"
  savedJobs: { [key: number]: IJobRequisition }
}

export interface IFetchSavedJobsFailure {
  type: "FETCH_SAVED_JOBS_FAILURE"
  error: Error
}

export interface IFetchDocumentsBegan {
  type: "FETCH_DOCUMENTS_BEGAN"
}

export interface IFetchDocumentsSuccess {
  type: "FETCH_DOCUMENTS_SUCCESS"
  documents: Document[]
}

export interface IFetchDocumentsFailure {
  type: "FETCH_DOCUMENTS_FAILURE"
  error: Error
}

export interface IMergePersonalForm {
  type: "MERGE_PERSONAL_FORM"
  formData: any
}

export interface IMergeExperienceForm {
  type: "MERGE_EXPERIENCE_FORM"
  formData: any
}

export interface IMergeSkillsForm {
  type: "MERGE_SKILLS_FORM"
  formData: any
}

export interface IMergeEducationRecordsForm {
  type: "MERGE_EDUCATION_RECORDS_FORM"
  formData: any
}

export interface IMergeReferencesForm {
  type: "MERGE_REFERENCES_FORM"
  formData: any
}

export interface IMergeLanguagesForm {
  type: "MERGE_LANGUAGES_FORM"
  formData: any
}

export interface IMergeMilitaryClearancesForm {
  type: "MERGE_MILITARY_CLEARANCES_FORM"
  formData: any
}

export interface ISetFormsToast {
  type: "SET_FORMS_TOAST"
  open: boolean
}

export type applicantActions =
  | IFetchApplicantBegan
  | IFetchApplicantSuccess
  | IFetchApplicantError
  | ICreateOrUpdateApplicantBegan
  | ICreateOrUpdateApplicantSuccess
  | ICreateOrUpdateApplicantFailure
  | ISetApplicantJsonPathValue
  | IDoesApplicantExistBegan
  | IDoesApplicantExistSuccess
  | IDoesApplicantExistFailure
  | IAddAdditionalCollectionElement
  | IRemoveCollectionElement
  | ISaveJobBegan
  | IDeleteSavedJobBegan
  | ISaveJobSuccess
  | IDeleteSavedJobSuccess
  | ISaveJobFailure
  | IDeleteSavedJobFailure
  | IPushSavedJob
  | IPopDeletedSavedJob
  | IFetchSavedJobsBegan
  | IFetchSavedJobsSuccess
  | IFetchSavedJobsFailure
  | IFetchDocumentsBegan
  | IFetchDocumentsSuccess
  | IFetchDocumentsFailure
  | IMergePersonalForm
  | IMergeExperienceForm
  | IMergeSkillsForm
  | IMergeEducationRecordsForm
  | IMergeReferencesForm
  | IMergeLanguagesForm
  | IMergeMilitaryClearancesForm
  | ISetFormsToast
  | IResetFullState
  | ILoginSuccess
  | ILogoutRequest

export const fetchApplicantBegan = (): IFetchApplicantBegan => ({
  type: "FETCH_APPLICANT_BEGAN",
})

export const fetchApplicantSuccess = (
  applicant: IApplicant,
): IFetchApplicantSuccess => ({
  type: "FETCH_APPLICANT_SUCCESS",
  applicant,
})

export const fetchApplicantError = (error: Error): IFetchApplicantError => ({
  type: "FETCH_APPLICANT_ERROR",
  error,
})

export const createOrUpdateApplicantBegan = (): ICreateOrUpdateApplicantBegan => ({
  type: "UPDATE_APPLICANT_BEGAN",
})

export const doesApplicantExistBegan = (): IDoesApplicantExistBegan => ({
  type: "DOES_APPLICANT_EXIST_BEGAN",
})

export const doesApplicantExistSuccess = (
  doesExist: boolean,
): IDoesApplicantExistSuccess => ({
  type: "DOES_APPLICANT_EXIST_SUCCESS",
  doesExist,
})

export const doesApplicantExistFailure = (
  error: Error,
): IDoesApplicantExistFailure => ({
  type: "DOES_APPLICANT_EXIST_ERROR",
  error,
})

export const createOrUpdateApplicantSuccess = (
  applicant: IApplicant,
): ICreateOrUpdateApplicantSuccess => ({
  type: "UPDATE_APPLICANT_SUCCESS",
  applicant,
})

export const createOrUpdateApplicantFailure = (
  error: Error,
): ICreateOrUpdateApplicantFailure => ({
  type: "UPDATE_APPLICANT_FAILURE",
  error,
})

export const setApplicantJsonPathValue = (
  path: string,
  value: any,
): ISetApplicantJsonPathValue => ({
  type: "SET_APPLICANT_JSON_PATH_VALUE",
  path,
  value,
})

export const addAdditionalCollectionElement = (
  path: string,
): IAddAdditionalCollectionElement => ({
  type: "ADD_ADDITIONAL_COLLECTION_ELEMENT",
  path,
})

export const removeCollectionElement = (
  path: string,
  index: number,
): IRemoveCollectionElement => ({
  type: "REMOVE_COLLECTION_ELEMENT",
  path,
  index,
})

export const saveJobBegan = (): ISaveJobBegan => ({
  type: "SAVE_JOB_BEGAN",
})

export const deleteSavedJobBegan = (): IDeleteSavedJobBegan => ({
  type: "DELETE_SAVED_JOB_BEGAN",
})

export const saveJobSuccess = (jobReqId: number): ISaveJobSuccess => ({
  type: "SAVE_JOB_SUCCESS",
  jobReqId,
})

export const deleteSavedJobSuccess = (
  jobReqId: number,
): IDeleteSavedJobSuccess => ({
  type: "DELETE_SAVED_JOB_SUCCESS",
  jobReqId,
})

export const saveJobFailure = (error: Error): ISaveJobFailure => ({
  type: "SAVE_JOB_FAILURE",
  error,
})

export const deleteSavedJobFailure = (
  error: Error,
): IDeleteSavedJobFailure => ({
  type: "DELETE_SAVED_JOB_FAILURE",
  error,
})

export const pushSavedJob = (jobReqId: number): IPushSavedJob => ({
  type: "PUSH_SAVED_JOB",
  jobReqId,
})

export const popDeletedSavedJob = (jobReqId: number): IPopDeletedSavedJob => ({
  type: "POP_DELETED_SAVED_JOB",
  jobReqId,
})

export const fetchSavedJobsBegan = (): IFetchSavedJobsBegan => ({
  type: "FETCH_SAVED_JOBS_BEGAN",
})

export const fetchSavedJobsSuccess = (savedJobs: {
  [key: number]: IJobRequisition
}): IFetchSavedJobsSuccess => ({
  type: "FETCH_SAVED_JOBS_SUCCESS",
  savedJobs,
})

export const fetchSavedJobsFailure = (
  error: Error,
): IFetchSavedJobsFailure => ({
  type: "FETCH_SAVED_JOBS_FAILURE",
  error,
})

export const fetchDocumentsBegan = (): IFetchDocumentsBegan => ({
  type: "FETCH_DOCUMENTS_BEGAN",
})

export const fetchDocumentsSuccess = (
  documents: Document[],
): IFetchDocumentsSuccess => ({
  type: "FETCH_DOCUMENTS_SUCCESS",
  documents,
})

export const fetchDocumentsFailure = (
  error: Error,
): IFetchDocumentsFailure => ({
  type: "FETCH_DOCUMENTS_FAILURE",
  error,
})

export const mergePersonalForm = (formData: any): IMergePersonalForm => ({
  type: "MERGE_PERSONAL_FORM",
  formData,
})

export const mergeExperienceForm = (formData: any): IMergeExperienceForm => ({
  type: "MERGE_EXPERIENCE_FORM",
  formData,
})

export const mergeSkillsForm = (formData: any): IMergeSkillsForm => ({
  type: "MERGE_SKILLS_FORM",
  formData,
})

export const mergeEducationRecordsForm = (
  formData: any,
): IMergeEducationRecordsForm => ({
  type: "MERGE_EDUCATION_RECORDS_FORM",
  formData,
})

export const mergeReferencesForm = (formData: any): IMergeReferencesForm => ({
  type: "MERGE_REFERENCES_FORM",
  formData,
})

export const mergeLanguagesForm = (formData: any): IMergeLanguagesForm => ({
  type: "MERGE_LANGUAGES_FORM",
  formData,
})

export const setFormsToast = (open: boolean): ISetFormsToast => ({
  type: "SET_FORMS_TOAST",
  open,
})

interface IBranchOfService {
  id: number
  name: string
  code: string
}

interface IRank {
  id: number
  name: string
  code: string
}

interface IMilitaryExperience {
  branchOfService: IBranchOfService
  rank: IRank
  monthsOfService: string
  notes: string
}

interface IGrantor {
  id: number
  name: string
  description: string
}

interface IType {
  id: number
  name: string
  description: string
}

interface IClearance {
  id: number
  active: boolean
  grantor: IGrantor
  type: IType
  expirationDate: Date
  comments: string
}

interface IMilitaryClearanceFormData {
  militaryExperienceRecords: IMilitaryExperience[]
  clearances: IClearance[]
}

export const mergeMilitaryClearancesForm = (
  formData: IMilitaryClearanceFormData,
): IMergeMilitaryClearancesForm => {
  return {
    type: "MERGE_MILITARY_CLEARANCES_FORM",
    formData,
  }
}

export const fetchApplicant = (
  token: string,
  cancelToken: CancelTokenSource,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchApplicantBegan())
    axios
      .get(`${getPortalApiURL()}/applicant`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken?.token,
      })
      .then(res => {
        const applicant = res.data

        // If canRead / canSpeak / canWrite for any of the applicant's languages
        // are undefined (or falsy), specifically set them to false
        // eslint-disable-next-line no-unused-expressions
        applicant?.languages.forEach(language => {
          /* eslint-disable no-param-reassign */
          language.canRead = language.canRead || false
          language.canSpeak = language.canSpeak || false
          language.canWrite = language.canWrite || false
          /* eslint-enable no-param-reassign */
        })
        dispatch(doesApplicantExistSuccess(true))
        dispatch(fetchApplicantSuccess(applicant))
      })
      .catch(error => {
        dispatch(fetchApplicantError(error))
      })
  }
}

export const createOrUpdateApplicant = (
  applicant: IApplicant,
  token: string,
  cancelToken: CancelTokenSource,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(createOrUpdateApplicantBegan())
    axios
      .post(`${getPortalApiURL()}/applicant`, applicant, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken?.token,
      })
      .then(res => dispatch(createOrUpdateApplicantSuccess(res.data)))
      .catch(err => dispatch(createOrUpdateApplicantFailure(err)))
  }
}

export const fetchSavedJobs = (
  token: string,
  cancelToken: CancelTokenSource,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchSavedJobsBegan())
    axios
      .get(`${getPortalApiURL()}/applicant/saved-jobs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelToken?.token,
      })
      .then(response => {
        const normalizedSavedJobs = normalize(response.data, [
          NormalizedSavedJobs,
        ]).entities.savedJobs
        dispatch(fetchSavedJobsSuccess(normalizedSavedJobs))
      })
      .catch(error => dispatch(fetchSavedJobsFailure(error)))
  }
}

export const fetchDocuments = (token: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchDocumentsBegan())
    axios
      .get(`${getPortalApiURL()}/document`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => dispatch(fetchDocumentsSuccess(res.data)))
      .catch(err => dispatch(fetchDocumentsFailure(err)))
  }
}

export const saveJob = (jobReqId: number, token: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveJobBegan())
    axios
      .post(
        `${getPortalApiURL()}/applicant/saved-job`,
        {
          job_req_id: jobReqId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => dispatch(saveJobSuccess(jobReqId)))
      .catch(err => dispatch(saveJobFailure(err)))
  }
}

export const deleteSavedJob = (jobReqId: number, token: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteSavedJobBegan())
    axios
      .delete(`${getPortalApiURL()}/applicant/saved-job/${jobReqId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => dispatch(deleteSavedJobSuccess(jobReqId)))
      .catch(err => dispatch(deleteSavedJobFailure(err)))
  }
}

const fetchAllApplicantInfo = (
  token: string,
  cancelToken: CancelTokenSource,
) => {
  return async (dispatch): Promise<void> => {
    dispatch(fetchApplicant(token, cancelToken))
    dispatch(fetchSavedJobs(token, cancelToken))
    dispatch(fetchJobApplicationsIfNeeded(token, cancelToken))
  }
}

export const handleLoginAction = (action$, state$): void =>
  action$.pipe(
    ofType("LOGIN_SUCCESS"),
    mergeMap(async () =>
      fetchAllApplicantInfo(
        state$.value.loginReducer.token,
        state$.value.general.cancelToken,
      ),
    ),
  )

const setApplicantExistAndFormToast = (exist, open) => {
  return async (dispatch): Promise<void> => {
    dispatch(doesApplicantExistSuccess(exist))
    dispatch(setFormsToast(open))
  }
}

export const handleCreateOrUpdateApplicantSuccessAction = (
  action$,
  state$,
): void =>
  action$.pipe(
    ofType("UPDATE_APPLICANT_SUCCESS"),
    mergeMap(async () => {
      if (!state$.value.applicantReducer.doesExist) {
        return setApplicantExistAndFormToast(true, true)
      }

      return setApplicantExistAndFormToast(true, false)
    }),
  )

export const handleDoesApplicantExistActions = (action$): void =>
  action$.pipe(
    ofType("DOES_APPLICANT_EXIST_SUCCESS", "DOES_APPLICANT_EXIST_ERROR"),
    filter((action: IDoesApplicantExistSuccess) => !action.doesExist),
    map(() => push("/profile")),
  )

export const handleSaveJobSuccess = (action$): void =>
  action$.pipe(
    ofType("SAVE_JOB_SUCCESS"),
    map((action: ISaveJobSuccess) => pushSavedJob(action.jobReqId)),
  )

export const handleDeleteJobSuccess = (action$): void =>
  action$.pipe(
    ofType("DELETE_SAVED_JOB_SUCCESS"),
    map((action: IDeleteSavedJobSuccess) =>
      popDeletedSavedJob(action.jobReqId),
    ),
  )

export const handleApplicantMergeActions = (
  action$,
  state$,
): Observable<Action<any>> =>
  action$.pipe(
    ofType(
      "MERGE_PERSONAL_FORM",
      "MERGE_EXPERIENCE_FORM",
      "MERGE_SKILLS_FORM",
      "MERGE_EDUCATION_RECORDS_FORM",
      "MERGE_REFERENCES_FORM",
      "MERGE_LANGUAGES_FORM",
      "MERGE_MILITARY_CLEARANCES_FORM",
    ),
    map(() =>
      createOrUpdateApplicant(
        state$.value.applicantReducer.applicant,
        state$.value.loginReducer.token,
        state$.value.general.cancelToken,
      ),
    ),
  )
