import cloneDeep from "lodash/cloneDeep"
import {
  IAddOrUpdateQuestionAnswer,
  ICreateApplicationFailure,
  IFetchJobApplicationsSuccess,
  IJobApplication,
  jobApplicationActions,
  ISetAlreadyApplied,
} from "../actions/jobApplicationActions"
import { IPortalJobApplication } from "../models/PortalJobApplication"

export interface IJobApplicationState {
  portalJobApplication: IPortalJobApplication
  jobApplications: { [id: number]: IJobApplication[] }
  isFetchingJobApplications: boolean
  isLoading: boolean
  error?: Error
  alreadyApplied: boolean
  validJobApplicationsCache: boolean
}

export const initialState: IJobApplicationState = {
  portalJobApplication: {
    answers: {},
    applicantId: 0,
    requisitionId: 0,
  },
  jobApplications: {},
  isFetchingJobApplications: false,
  isLoading: false,
  error: undefined,
  alreadyApplied: false,
  validJobApplicationsCache: false,
}

function handleAddOrUpdateQuestionAnswerAction(
  state: IJobApplicationState,
  action: IAddOrUpdateQuestionAnswer,
): IJobApplicationState {
  const newPortalJobApplication = cloneDeep(state.portalJobApplication)
  let questionGroup = newPortalJobApplication[action.answer.questionGroupId]

  if (!questionGroup) {
    questionGroup = {}
  }

  if (!questionGroup[action.answer.questionId]) {
    questionGroup[action.answer.questionId] = {}
  }

  questionGroup[action.answer.questionId] = {
    answerId: action.answer.answerId,
    answerText: action.answer.answer,
    questionWrapper: action.answer.questionWrapperId,
    id: action.answer.id,
  }

  newPortalJobApplication[action.answer.questionGroupId] = questionGroup

  return {
    ...state,
    portalJobApplication: newPortalJobApplication,
  }
}

function handleCreateApplicationFailureAction(
  state: IJobApplicationState,
  action: ICreateApplicationFailure,
): IJobApplicationState {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  }
}

function handleCreateApplicationSuccessAction(
  state: IJobApplicationState,
): IJobApplicationState {
  return {
    ...state,
    isLoading: false,
    error: null,
  }
}

function handleCreateApplicationBeganAction(
  state: IJobApplicationState,
): IJobApplicationState {
  return {
    ...state,
    isLoading: true,
  }
}

function handleFetchJobApplicationsBegan(
  state: IJobApplicationState,
): IJobApplicationState {
  return {
    ...state,
    isFetchingJobApplications: true,
  }
}

function handleFetchJobApplicationsSuccess(
  state: IJobApplicationState,
  action: IFetchJobApplicationsSuccess,
): IJobApplicationState {
  return {
    ...state,
    isFetchingJobApplications: false,
    jobApplications: action.jobApplications,
    validJobApplicationsCache: true,
  }
}

function handleFetchJobApplicationsFailure(
  state: IJobApplicationState,
): IJobApplicationState {
  return {
    ...state,
    isFetchingJobApplications: false,
    validJobApplicationsCache: false,
  }
}

function handleSetAlreadyApplied(
  state: IJobApplicationState,
  action: ISetAlreadyApplied,
): IJobApplicationState {
  return {
    ...state,
    alreadyApplied: action.applied,
  }
}

function handleInvalidateJobApplicationsCache(
  state: IJobApplicationState,
): IJobApplicationState {
  return {
    ...state,
    validJobApplicationsCache: false,
  }
}

export function jobApplicationReducer(
  state = initialState,
  action: jobApplicationActions,
): IJobApplicationState {
  switch (action.type) {
    case "FETCH_JOB_APPLICATIONS_BEGAN":
      return handleFetchJobApplicationsBegan(state)
    case "FETCH_JOB_APPLICATIONS_SUCCESS":
      return handleFetchJobApplicationsSuccess(state, action)
    case "FETCH_JOB_APPLICATIONS_FAILURE":
      return handleFetchJobApplicationsFailure(state)
    case "CREATE_APPLICATION_BEGAN":
      return handleCreateApplicationBeganAction(state)
    case "CREATE_APPLICATION_SUCCESS":
      return handleCreateApplicationSuccessAction(state)
    case "CREATE_APPLICATION_FAILURE":
      return handleCreateApplicationFailureAction(state, action)
    case "ADD_OR_UPDATE_QUESTION_ANSWER":
      return handleAddOrUpdateQuestionAnswerAction(state, action)
    case "SET_ALREADY_APPLIED":
      return handleSetAlreadyApplied(state, action)
    case "INVALIDATE_JOB_APPLICATIONS_CACHE":
      return handleInvalidateJobApplicationsCache(state)
    case "RESET_FULL_STATE":
      return initialState
    default:
      return state
  }
}
