import { sidebarActions } from "../actions/sidebarActions"

interface ISidebarState {
  isVisible: boolean
}

export const initialState: ISidebarState = {
  isVisible: false,
}

function handleToggleSidebarAction(state: ISidebarState): ISidebarState {
  return {
    isVisible: !state.isVisible,
  }
}

export function sidebarReducer(
  state = initialState,
  action: sidebarActions,
): ISidebarState {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return handleToggleSidebarAction(state)
    case "RESET_FULL_STATE":
      return initialState
    default:
      return state
  }
}
