import { IResetFullState } from "./generalActions"

export interface IToggleSidebar {
  type: "TOGGLE_SIDEBAR"
}

export type sidebarActions = IToggleSidebar | IResetFullState

export const toggleSidebar = (): IToggleSidebar => ({
  type: "TOGGLE_SIDEBAR",
})
