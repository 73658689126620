import { applicantCompleteStatus, Env, tokenKey } from "../constants/constants"

export function getCurrentEnvironment(): Env {
  return Env[process.env.REACT_APP_ENV]
}

export const getVal = (val): string => {
  if (!val) return ""
  return val
}

export function doesApplicantExist(): boolean {
  const doesExist = window.localStorage.getItem(tokenKey)
  if (doesExist === null) {
    return false
  }

  return doesExist === applicantCompleteStatus
}

export function setApplicantStatus(status): void {
  window.localStorage.setItem(tokenKey, status)
}

export function removeApplicantStatus(): void {
  window.localStorage.removeItem(tokenKey)
}
