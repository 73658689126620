import {
  ILogoutRequest,
  ILoginSuccess,
} from "haystack-auth/src/actions/loginActions"

export type GeneralActions =
  | IResizeViewport
  | IResetFullState
  | ILogoutRequest
  | ILoginSuccess

export interface IResetFullState {
  type: "RESET_FULL_STATE"
}

export interface IResizeViewport {
  type: "RESIZE_VIEWPORT"
  size: number
}

export const resetFullState = (): IResetFullState => ({
  type: "RESET_FULL_STATE",
})

export const handleResize = (size: number): IResizeViewport => ({
  type: "RESIZE_VIEWPORT",
  size,
})
